export default class NumberFormat {
  static toRomanNumbers(number: number): string {
    const digits = String(+number).split("");
    const key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
    ];
    let roman = "";
    let i = 3;
    while (i--) {
      const last = digits.pop();
      if (last) roman = (key[+last + i * 10] || "") + roman;
    }
    return Array(+digits.join("") + 1).join("M") + roman;
  }
}
