import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const CentralBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "inherit",
})) as typeof Box;

export default CentralBox;
