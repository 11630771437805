import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import FeedbackForm from "features/feedback-form";

const sx = {
  pageBox: {
    display: "flex",
    alignItems: "center",
  },
};

const Feedback: FC = () => {
  return (
    <PageBox sx={sx.pageBox}>
      <FeedbackForm />
    </PageBox>
  );
};

export default Feedback;
