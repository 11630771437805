import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import ContactInfo from "entities/contact-info";

const sx = {
  pageBox: {
    display: "flex",
    alignItems: "center",
  },
};

const Contacts: FC = () => {
  return (
    <PageBox sx={sx.pageBox}>
      <ContactInfo />
    </PageBox>
  );
};

export default Contacts;
