import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { type FC, useLayoutEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import type { Theme } from "@mui/material/styles";
import DeleteModalContextProvider from "shared/lib/context/DeleteModal/provider";
import useDictionary from "shared/lib/hooks/useDictionary";
import FormatDate from "shared/lib/helpers/format/DateFormat";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import {
  cito,
  typeOfPayment,
  typeOfResearch,
} from "shared/lib/constants/selectOptions";
import PetComponent from "entities/pet";
import TextFieldWithTemplate from "shared/UI/textfield-template";
import MaterialsTable from "entities/materials-table";

const sx = {
  typography: {
    fontWeight: "bold",
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
};

const HistologyInputForm: FC = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const { control, setValue, watch } = useFormContext();
  const dictionary = useDictionary();

  const method = useMemo(
    () => OptionFormat.getOption("method", dictionary.data),
    [dictionary.data],
  );

  const params = useParams();

  useLayoutEffect(() => {
    if (!params.documentId) {
      setValue("document", {
        type: "1",
        cito: "1",
        pay: "1",
        method: "1",
        sendTime: new Date().toLocaleTimeString().slice(0, -3),
        sendDate: FormatDate.getDate("today"),
        samplingDate: FormatDate.getDate("today"),
        materials: [],
      });
    }
    setIsReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.documentId]);

  if (isReady)
    return (
      <Box>
        <PetComponent />
        <Typography component="p" variant="subtitle1" sx={sx.typography}>
          Исследование
        </Typography>
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <Controller
              name="document.type"
              control={control}
              render={({ field }) => (
                <Select {...field} size="small" fullWidth>
                  {typeOfResearch.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Controller
              name="document.cito"
              control={control}
              render={({ field }) => (
                <Select {...field} size="small" fullWidth>
                  {cito.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Controller
              name="document.pay"
              control={control}
              render={({ field }) => (
                <Select {...field} size="small" fullWidth>
                  {typeOfPayment.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <FormControl fullWidth>
              <InputLabel size="small" id="method-label">
                Метод получения
              </InputLabel>
              <Controller
                name="document.method"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="method-label"
                    label="Метод получения"
                    size="small"
                  >
                    {method &&
                      method.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Controller
              name="document.samplingDate"
              control={control}
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  type="date"
                  label="Дата получения"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Controller
              name="document.sendDate"
              control={control}
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  type="date"
                  label="Дата отправки"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Controller
              name="document.sendTime"
              control={control}
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  type="time"
                  label="Время отправки"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{ pt: 1, pb: 1 }}>
          <DeleteModalContextProvider>
            <MaterialsTable />
          </DeleteModalContextProvider>
        </Box>
        <Box sx={{ pt: 1, pb: 1 }}>
          <TextFieldWithTemplate
            label="Краткие клинические данные"
            name="clinic"
            value={watch("document.clinic")}
            changeHandler={(value: string) =>
              setValue("document.clinic", value)
            }
          />
        </Box>
      </Box>
    );
  return null;
};

export default HistologyInputForm;
