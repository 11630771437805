export const typeOfResearch = [
  {
    value: `1`,
    name: `первичное`,
  },
  {
    value: `2`,
    name: `повторное`,
  },
];

export const cito = [
  {
    value: `1`,
    name: `плановое`,
  },
  {
    value: `2`,
    name: `срочное`,
  },
  {
    value: `3`,
    name: `интраоперационное`,
  },
];

export const typeOfPayment = [
  {
    value: `1`,
    name: `бесплатно`,
  },
  {
    value: `2`,
    name: `платно РБ`,
  },
  {
    value: `3`,
    name: `платно ИГ`,
  },
  {
    value: `4`,
    name: `страхование`,
  },
  {
    value: `5`,
    name: `сотрудник`,
  },
];

export const gender = [
  {
    value: `1`,
    name: `муж.`,
  },
  {
    value: `2`,
    name: `жен.`,
  },
];
