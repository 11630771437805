import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import type { FC } from "react";
import { Link } from "react-router-dom";
import type { Theme } from "@mui/material/styles";
import type { IContract } from "app/types/contract";

const sx = {
  list: {
    width: "100%",
    color: "primary.dark",
  },
  listItem: {
    "& > a": {
      width: "100%",
    },
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
};

interface ContractLinksProps {
  contract: IContract;
}

const CardLinks: FC<ContractLinksProps> = ({ contract }) => {
  const { services, id } = contract;
  return (
    <List sx={sx.list}>
      {services?.map(({ code, name }) => (
        <ListItem key={`${id}/${code}`} disablePadding sx={sx.listItem}>
          <Link to={`/${id}/${code}`}>
            <ListItemButton>
              <ListItemText>{name}</ListItemText>
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
      <ListItem disablePadding sx={sx.listItem}>
        <Link to={`/${id}/journals`}>
          <ListItemButton>
            <ListItemText>Журналы</ListItemText>
          </ListItemButton>
        </Link>
      </ListItem>
    </List>
  );
};

export default CardLinks;
