import { type FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFetchDocumentsQuery } from "../../../pages/[service]/home/api/documentApi";
import DocumentsTable from "./DocumentsTable";
import CentralBox from "shared/UI/central-box";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import DeleteModalContextProvider from "shared/lib/context/DeleteModal/provider";
import type { Data } from "app/types/document";
import type { TServiceCode } from "app/types/app";
import type { TOrganization } from "app/types/contract";
import NotFound from "shared/UI/not-found";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import { searchbarSelector } from "pages/[service]/home/redux/SearchbarSlice";
import useContract from "shared/lib/hooks/useContract";
import Msg from "shared/UI/error-message";

const sx = {
  centralBox: {
    minHeight: {
      xs: "calc(100vh - 19.5625rem)",
      sm: "calc(100vh - 16.5625rem)",
      md: "calc(100vh - 6rem)",
    },
  },
};

const DocumentList: FC = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Data[]>([]);
  const { range } = useAppSelector(searchbarSelector);
  const params = useParams();
  const { data: contractData } = useContract();

  const serviceCode = params.serviceCode as TServiceCode;
  const organizationGuid: TOrganization["guid"] =
    contractData?.service?.guid || "";

  const data = {
    serviceCode,
    organizationGuid,
    payload: range,
  };
  const {
    data: result,
    error,
    isFetching,
  } = useFetchDocumentsQuery(data, {
    skip: !organizationGuid,
  });

  useEffect(() => {
    if (result) setDocuments(result.referrals);
  }, [result]);

  useEffect(() => {
    if (isFetching) {
      setIsReady(false);
    } else {
      setIsReady(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (error)
      toast.error(
        <Msg title="Произошла ошибка получения записей!" message={error} />,
      );
  }, [error]);

  if (!isReady)
    return (
      <CentralBox sx={sx.centralBox}>
        <FullSizeCircularProgress />
      </CentralBox>
    );

  return documents.length ? (
    <DeleteModalContextProvider>
      <DocumentsTable rows={documents} />
    </DeleteModalContextProvider>
  ) : (
    <NotFound sx={sx.centralBox} />
  );
};

export default DocumentList;
