import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import type { FC, ReactNode } from "react";
import Copyright from "shared/UI/copyright";

const sx = {
  container: {
    position: "relative",
    zIndex: "1",
    p: 0,
  },
  paper: {
    p: {
      xs: 2,
      sm: 5,
    },
  },
  formWrapper: {
    mt: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "19rem",
  },
};

interface Props {
  children: ReactNode;
}

const LoginCard: FC<Props> = ({ children }) => {
  return (
    <Container component="div" maxWidth="sm" sx={sx.container}>
      <Paper elevation={2} sx={sx.paper}>
        <Box sx={sx.formWrapper}>{children}</Box>
        <Copyright />
      </Paper>
    </Container>
  );
};

export default LoginCard;
