import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import DocumentList from "widgets/documents";
import ContractWrapper from "widgets/wrappers/ContractWrapper";
import DocumentsSearchbar from "features/documents-searchbar";

const Service: FC = () => {
  return (
    <ContractWrapper>
      <PageBox>
        <DocumentsSearchbar />
        <DocumentList />
      </PageBox>
    </ContractWrapper>
  );
};

export default Service;
