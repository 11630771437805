import { FC, ReactNode } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import type { Breakpoint, Theme } from "@mui/material";

const sx = {
  container: {
    p: "0 !important",
  },
  content: {
    p: {
      xs: 2,
      sm: 5,
    },
  },
  typography: {
    fontWeight: "bold",
    textAlign: "center",
    mb: 3,
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
};

interface CardProps {
  children: ReactNode;
  title: string;
  maxWidth?: false | Breakpoint;
}

const Card: FC<CardProps> = ({ children, title, maxWidth }) => {
  return (
    <Container maxWidth={maxWidth} sx={sx.container}>
      <Paper elevation={2}>
        <Box sx={sx.content}>
          <Typography component="h5" variant="h4" sx={sx.typography}>
            {title}
          </Typography>
          <Box>{children}</Box>
        </Box>
      </Paper>
    </Container>
  );
};

Card.defaultProps = {
  maxWidth: false,
};

export default Card;
