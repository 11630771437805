import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { QRCodeSVG } from "qrcode.react";
import type { FC } from "react";
import { useParams } from "react-router-dom";
import MaterialsTablePrint from "./MaterialsTablePrint";
import useDocument from "shared/lib/hooks/useDocument";
import FormatDate from "shared/lib/helpers/format/DateFormat";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import useDictionary from "shared/lib/hooks/useDictionary";
import useContract from "shared/lib/hooks/useContract";

const sx = {
  a4: {
    maxWidth: "21cm",
    margin: "auto",
    position: "relative",
  },
  paid: {
    position: "absolute",
    top: "3rem",
    width: 200,
  },
  typographyTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },
  signContainer: {
    display: "flex",
    alignItems: "end",
    gap: 1,
    justifyContent: "end",
    ml: "auto",
  },
};

const HistologyReferral: FC = () => {
  const params = useParams();
  const guid = params.documentId || "";
  const { data: contractData } = useContract();
  const { data: dictionaryData } = useDictionary();
  const { data: documentData } = useDocument();

  const getDiagnoisis = () => {
    if (documentData?.referral) {
      const { diagnosisCode, diagnosisName } = documentData.referral;
      if (diagnosisCode) {
        return diagnosisName
          ? diagnosisCode + ": " + diagnosisName
          : diagnosisCode;
      } else {
        if (diagnosisName) return diagnosisName;
      }
    }
    return null;
  };

  return (
    <Box sx={sx.a4}>
      <Grid container>
        <Grid item xs={11}>
          <Typography variant="subtitle1" component="p">
            {documentData?.organization?.name}
          </Typography>
          <Typography variant="subtitle1" component="p">
            {OptionFormat.returnOptionValue(
              "department",
              documentData?.organization?.department,
              dictionaryData,
            )}
          </Typography>
        </Grid>
        <Grid item ml="auto" xs={1}>
          <QRCodeSVG value={guid} size={64} />
        </Grid>
      </Grid>
      {(documentData?.referral.pay === "2" ||
        documentData?.referral.pay === "3") && (
        <Box
          sx={sx.paid}
          component="img"
          src="/images/PAY.png"
          alt="paid-img"
        />
      )}
      <Typography variant="h5" component="h3" sx={sx.typographyTitle}>
        НАПРАВЛЕНИЕ
      </Typography>
      <Typography variant="h6" component="h4" sx={sx.typographyTitle}>
        на патогистологическое исследование
      </Typography>
      <Typography variant="subtitle1" component="p">
        В {contractData?.service?.name}
      </Typography>
      <Typography variant="subtitle1" component="p">
        1. Фамилия, собственное имя, отчество (если таковое имеется) пациента:{" "}
        {documentData?.patient.lastName} {documentData?.patient.firstName}{" "}
        {documentData?.patient.secondName}
      </Typography>
      <Typography variant="subtitle1" component="p">
        2. Идентификационный номер пациента:{" "}
        {documentData?.patient.personalNumber}
      </Typography>
      <Typography variant="subtitle1" component="p">
        3. Число, месяц, год рождения:{" "}
        {FormatDate.toNormalDate(documentData?.patient.birthDate || "")}
      </Typography>
      <Typography variant="subtitle1" component="p">
        4. Пол: {documentData?.patient.gender === "1" ? "муж." : "жен."}
      </Typography>
      <Typography variant="subtitle1" component="p">
        5. Адрес места жительства (места пребывания):{" "}
        {documentData?.patient.address.address}
      </Typography>
      <Typography variant="subtitle1" component="p">
        6. Исследование:{" "}
        {documentData?.referral.type === "1" ? "первичное" : "повторное"}
      </Typography>
      <Typography variant="subtitle1" component="p">
        7. Дата забора биопсийного (операционного) материала:{" "}
        {FormatDate.toNormalDate(documentData?.referral.samplingDate)}
      </Typography>
      <Typography variant="subtitle1" component="p">
        8. Способ получения биопсийного (операционного) материала:{" "}
        {OptionFormat.returnOptionValue(
          "method",
          documentData?.referral.method,
          dictionaryData,
        )}
      </Typography>
      <Typography variant="subtitle1" component="p">
        9. Дата отправки:{" "}
        {FormatDate.toNormalDate(documentData?.referral.sendDate)}
      </Typography>
      <Typography variant="subtitle1" component="p">
        10. Время отправки (заполняется при экспресс биопсиях с указанием
        времени в часах и минутах):{" "}
        {documentData?.referral.sendTime &&
          documentData?.referral.sendTime.slice(0, 5)}
      </Typography>
      <Typography variant="subtitle1" component="p">
        11. Маркировка биопсийного (операционного) материала:
      </Typography>
      <MaterialsTablePrint materials={documentData?.referral.materials} />
      <Typography variant="subtitle1" component="p">
        12. Краткие клинические данные (основные проявления, их
        продолжительность, результаты инструментальных и лабораторных
        исследований, информация о лечении): {documentData?.referral.clinic}
      </Typography>
      <Typography variant="subtitle1" component="p">
        13. Диагноз: {getDiagnoisis()}
      </Typography>
      <Grid container spacing={1} mt={1} sx={{ pageBreakInside: "avoid" }}>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="p">
            Врач-специалист, направивший материал на патогистологическое
            исследование
          </Typography>
        </Grid>
        <Grid item container xs={8} sx={sx.signContainer}>
          <Grid
            item
            xs={3}
            sx={{ textAlign: "center", ml: "auto", mt: "auto" }}
          >
            <Box sx={{ borderTop: "1px solid" }}>
              <Typography variant="subtitle2" component="p">
                (подпись)
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "center", ml: 1, mt: "auto" }}>
            <Typography variant="subtitle1" component="p">
              {documentData?.doctor.lastName} {documentData?.doctor.firstName}{" "}
              {documentData?.doctor.secondName}
            </Typography>
            <Box sx={{ borderTop: "1px solid" }}>
              <Typography variant="subtitle2" component="p">
                (фамилия, инициалы)
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HistologyReferral;
