import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const LoginWrapperBox = styled(Box)(() => ({
  minHeight: "inherit",
  display: "flex",
  alignItems: "center",
})) as typeof Box;

export default LoginWrapperBox;
