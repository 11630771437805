type THeadCell = {
  id: number;
  label: string;
};

export const HeadCells: THeadCell[] = [
  {
    id: 1,
    label: `№ п/п`,
  },
  {
    id: 2,
    label: `Фамилия, собственное имя, отчество (если таковое имеется) пациента; число, месяц, год рождения, пациента`,
  },
  {
    id: 3,
    label: `Адрес места жительства (места пребывания) пациента`,
  },
  {
    id: 4,
    label: `Предварительный диагноз`,
  },
  {
    id: 5,
    label: `Дата забора биопсийного (операционного) материала`,
  },
  {
    id: 6,
    label: `Порядковый номер протокола операции`,
  },
  {
    id: 7,
    label: `Маркировка емкости, в которой транспортируется биопсийный (операционный) материал`,
  },
  {
    id: 8,
    label: `Вид биопсийного (операционного) материала (название ткани или органа, анатомотопографическая локализация)`,
  },
  {
    id: 9,
    label: `Характер патологического процесса (эрозия, язва, полип, узел, отношение к окружающим тканям и прочие особенности)`,
  },
  {
    id: 10,
    label: `Количество или объем направленных фрагментов тканей, органов или последов`,
  },
  {
    id: 11,
    label: `Фамилия, собственное имя, отчество (если таковое имеется) врача-специалиста, медицинской сестры`,
  },
  {
    id: 12,
    label: `Заключение патогистологического исследования`,
  },
];
