import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import TemplatesWidget from "widgets/templates";
import DeleteModalContextProvider from "shared/lib/context/DeleteModal/provider";
import TemplateModalContextProvider from "shared/lib/context/TemplateModal/provider";

const Templates: FC = () => {
  return (
    <DeleteModalContextProvider>
      <TemplateModalContextProvider>
        <PageBox>
          <TemplatesWidget />
        </PageBox>
      </TemplateModalContextProvider>
    </DeleteModalContextProvider>
  );
};

export default Templates;
