import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import type { FC } from "react";
import Copyright from "shared/UI/copyright";
import { Contacts } from "../config";
import Card from "shared/UI/card";

const sx = {
  typography: {
    fontWeight: "bold",
    textAlign: "center",
    mb: 3,
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
  row: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  text: {
    fontSize: {
      xs: 15,
      sm: 20,
    },
  },
  companyNameWrapper: {
    color: (theme: Theme) => theme.palette.grey[700],
    mb: 2,
  },
};

const ContactInfo: FC = () => {
  return (
    <Card maxWidth="md" title="Контакты">
      <Box sx={sx.companyNameWrapper}>
        <Typography component="p" variant="subtitle1" sx={sx.text}>
          ЧУП «Белинновация». УНП 291071899
        </Typography>
      </Box>
      {Contacts.map((contact) => (
        <Box key={contact.id} sx={sx.row}>
          {contact.icon}
          <Typography component="p" variant="subtitle1" sx={sx.text}>
            {contact.text}{" "}
            <Link {...contact.linkProps}>{contact.linkText}</Link>
          </Typography>
        </Box>
      ))}
      <Copyright />
    </Card>
  );
};

export default ContactInfo;
