import type { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { type FC, type KeyboardEvent, useMemo } from "react";
import {
  Controller,
  FieldError,
  useFormContext,
  FieldErrorsImpl,
  Merge,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import useDictionary from "shared/lib/hooks/useDictionary";
import type { TPatient } from "app/types/document";
import InputFormat from "shared/lib/helpers/format/InputFormat";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import { gender } from "shared/lib/constants/selectOptions";
import Address from "entities/address";

const sx = {
  typography: {
    fontWeight: "bold",
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
  capitalize: {
    textTransform: "capitalize",
  },
};

const Patient: FC = () => {
  const {
    control,
    watch,
    setValue,
    formState: {
      errors: { patient },
    },
  } = useFormContext();
  const changeHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const result = InputFormat.changePassportNumber(e);
    setValue("patient.personalNumber", result);
  };

  const dictionary = useDictionary();
  const params = useParams();

  const department = useMemo(
    () => OptionFormat.getOption("department", dictionary.data),
    [dictionary.data],
  );

  const typedPatient: Merge<FieldError, FieldErrorsImpl<TPatient>> | undefined =
    patient;

  return (
    <Box>
      <Typography component="p" variant="subtitle1" sx={sx.typography}>
        Пациент
      </Typography>
      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4}>
          <Controller
            name="patient.lastName"
            control={control}
            rules={{ required: "Введите фамилию" }}
            render={({ field }) => (
              <TextField
                type="text"
                label="Фамилия"
                inputProps={{ sx: sx.capitalize }}
                size="small"
                {...field}
                error={Boolean(typedPatient && typedPatient.lastName)}
                helperText={
                  typedPatient &&
                  typedPatient.lastName &&
                  typedPatient.lastName.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Controller
            name="patient.firstName"
            control={control}
            rules={{ required: "Введите имя" }}
            render={({ field }) => (
              <TextField
                type="text"
                label="Имя"
                inputProps={{ sx: sx.capitalize }}
                size="small"
                {...field}
                error={Boolean(typedPatient && typedPatient.firstName)}
                helperText={
                  typedPatient &&
                  typedPatient.firstName &&
                  typedPatient.firstName.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Controller
            name="patient.secondName"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                label="Отчество"
                size="small"
                inputProps={{ sx: sx.capitalize }}
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={3}>
          <Controller
            name="patient.birthDate"
            control={control}
            render={({ field }) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Дата рождения"
                size="small"
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <TextField
            type="text"
            value={watch("patient.personalNumber")}
            onKeyUp={changeHandler}
            label="Идентификационный номер"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={2} sm={4} md={3}>
          <FormControl fullWidth>
            <InputLabel size="small" id="department-label">
              Отделение
            </InputLabel>
            <Controller
              name="organization.department"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="department-label"
                  label="Отделение"
                  size="small"
                >
                  {department &&
                    department.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} sm={4} md={2}>
          <FormControl fullWidth>
            <InputLabel size="small" id="gender-label">
              Пол
            </InputLabel>
            <Controller
              name="patient.gender"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="gender-label"
                  label="Пол"
                  size="small"
                >
                  {gender.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {params.serviceCode === "autopsy" && (
          <>
            <Grid item xs={2} sm={4} md={6}>
              <Controller
                name="patient.placeWork"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label="Место работы"
                    size="small"
                    {...field}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Controller
                name="patient.positionWork"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    label="Должность"
                    size="small"
                    {...field}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Address />
    </Box>
  );
};

export default Patient;
