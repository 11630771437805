import { Button, SelectChangeEvent, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import {
  ChangeEvent,
  // FocusEvent,
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";

import useDictionary from "shared/lib/hooks/useDictionary";
import type { TValue } from "app/types/dictionary";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import TextFieldWithTemplate from "shared/UI/textfield-template";
import type { TMaterial } from "./MaterialsTable";
// import MarkFormat from "../../../../../helpers/format/MarkFormat";

const sx = {
  modalContent: {
    position: "absolute",
    top: "50%",
    left: {
      xs: "5%",
      sm: "50%",
    },
    right: {
      xs: "5%",
      sm: "auto",
    },
    transform: {
      xs: "translate(0, -50%)",
      sm: "translate(-50%, -50%)",
    },
    width: "auto",
    maxWidth: 500,
    bgcolor: "background.default",
    borderRadius: "4px",
    boxShadow: 24,
    p: {
      xs: 2,
      sm: 4,
    },
  },
};

interface IProps {
  open: boolean;
  close: () => void;
  material: TMaterial;
  changeMaterial: Dispatch<SetStateAction<TMaterial>>;
  add: () => void;
}

const MaterialsTableModal: FC<IProps> = (props) => {
  const { open, close, material, changeMaterial, add } = props;
  const [error, setError] = useState<string>("");
  const dictionary = useDictionary();
  const materialOptions = useMemo(
    () => OptionFormat.getOption("material", dictionary.data),
    [dictionary.data],
  );

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    changeMaterial((prevState: TMaterial) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const changeSelectHandler = (e: SelectChangeEvent) => {
    changeMaterial((prevState: TMaterial) => ({
      ...prevState,
      materialType: e.target.value,
    }));
  };

  const changeDescriptionHandler = (value: string) => {
    changeMaterial((prevState: TMaterial) => ({
      ...prevState,
      description: value,
    }));
  };

  const clickHandler = () => {
    if (!material.number) {
      setError("Введите маркировку");
    } else {
      add();
    }
  };

  // const checkMark = (e: FocusEvent<HTMLInputElement>) => {
  //   const isValid = MarkFormat.validate(e.target.value)
  //   if(!isValid) setError('Неверная маркировка')
  // };

  useEffect(() => {
    if (material.number) setError("");
  }, [material.number]);

  return (
    <Modal open={open} onClose={close} aria-labelledby="material-modal">
      <Box sx={sx.modalContent}>
        <Typography
          id="modal-quit"
          variant="h5"
          component="h2"
          color="text.primary"
          sx={{ textAlign: "center" }}
        >
          Добавить материал
        </Typography>
        <Box component="div" sx={{ mt: 2 }}>
          <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12}>
              <TextField
                autoFocus
                error={Boolean(error)}
                value={material.number}
                onChange={changeHandler}
                // onBlur={checkMark}
                name="number"
                label="Маркировка"
                type="text"
                size="small"
                fullWidth
                helperText={error}
              />
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={material.materialType}
                  name="materialType"
                  onChange={changeSelectHandler}
                >
                  {materialOptions &&
                    materialOptions.map((item: TValue) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <TextFieldWithTemplate
                label="Описание"
                name="description"
                maxRows={10}
                value={material.description}
                changeHandler={changeDescriptionHandler}
              />
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <TextField
                value={material.count}
                onChange={changeHandler}
                name="count"
                label="Количество"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} sx={{ ml: "auto" }}>
              <Button
                size="large"
                variant="contained"
                onClick={clickHandler}
                fullWidth
              >
                Добавить
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default MaterialsTableModal;
