import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { RefreshTokenData, TokenResponse } from "app/types/user";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;

const refreshAPI = createApi({
  reducerPath: "refreshAPI",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: (build) => ({
    refresh: build.mutation<TokenResponse, RefreshTokenData>({
      query: (data) => ({
        url: "/authorization/user/refresh",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("refresh_token", data.refresh_token);
        } catch (error) {
          await Promise.reject(error);
        }
      },
    }),
  }),
});

export const refresh = (data: RefreshTokenData) =>
  refreshAPI.endpoints.refresh.initiate(data);

export default refreshAPI;
