import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAppSelector from "./useAppSelector";
import {
  useCreateTemplateMutation,
  useEditTemplateMutation,
} from "pages/templates/[service]";
import userSelector from "shared/redux/user/UserSelector";
import type { ITemplate } from "widgets/templates/types";
import ErrorFormat from "shared/lib/helpers/format/ErrorFormat";
import type { TServiceCode } from "app/types/app";

interface TemplateState {
  title: string;
  text: string;
}

const useTemplateModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const initialState = {
    title: "",
    text: "",
  };
  const [template, setTemplate] = useState<TemplateState>(initialState);
  const [fieldName, setFieldName] = useState<string>("");
  const [fieldTitle, setFieldTitle] = useState<string>("");

  const [templateForEdit, setTemplateForEdit] = useState<ITemplate | null>(
    null,
  );

  const [create, { isSuccess, error }] = useCreateTemplateMutation();
  const [edit, editOptions] = useEditTemplateMutation();
  const { user } = useAppSelector(userSelector);
  const params = useParams();

  const close = () => {
    setIsOpen(false);
    setFieldName("");
    setFieldTitle("");
    setTemplateForEdit(null);
    setTemplate(initialState);
  };

  const openCreateTemplateMode = (name: string, label: string) => {
    setIsOpen(true);
    setFieldName(name);
    setFieldTitle(label);
  };

  const openEditTemplateMode = (templ: ITemplate) => {
    setIsOpen(true);
    setTemplate({ title: templ.sampleTitle, text: templ.sampleText });
    setTemplateForEdit(templ);
  };

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTemplate((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submit = () => {
    if (templateForEdit) {
      const data = {
        id: templateForEdit.id.toString(),
        guid: user?.guid || "",
        payload: {
          sampleText: template.text,
          sampleTitle: template.title,
        },
      };
      edit(data);
    } else {
      const data = {
        fieldTitle,
        fieldName,
        guid: user?.guid || "",
        moduleTitle: params.serviceCode as TServiceCode,
        sampleTitle: template.title,
        sampleText: template.text,
      };
      create(data);
    }
    close();
  };

  useEffect(() => {
    if (isSuccess) {
      setTemplate(initialState);
      toast.success("Шаблон успешно добавлен!");
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (error)
      toast.error(
        `Ошибка добавления шаблона: ${ErrorFormat.getMessage(error)}`,
      );
  }, [error]);

  useEffect(() => {
    if (editOptions.isSuccess) {
      setTemplate(initialState);
      toast.success("Шаблон успешно отредактирован!");
    }
    // eslint-disable-next-line
  }, [editOptions.isSuccess]);

  useEffect(() => {
    if (editOptions.error)
      toast.error(
        `Ошибка редактирования шаблона: ${ErrorFormat.getMessage(
          editOptions.error,
        )}`,
      );
  }, [editOptions.error]);

  return {
    isOpen,
    openCreateTemplateMode,
    close,
    template,
    changeHandler,
    submit,
    openEditTemplateMode,
  };
};

export default useTemplateModal;

export type TTemplateModal = ReturnType<typeof useTemplateModal>;
