import { Status } from "../types";

const getStatus = (status: string) => {
  switch (status) {
    case "received":
      return Status.RECEIVED;

    case "registered":
      return Status.REGISTERED;

    case "in-progress":
      return Status.IN_PROGRESS;

    case "complite":
      return Status.COMPLITE;

    case "completed":
      return Status.COMPLITE;

    default:
      return "—";
  }
};

export default getStatus;
