import type { FC } from "react";
import { useParams } from "react-router-dom";
import PageBox from "shared/UI/page-box";
import Content from "shared/lib/helpers/get-content/Content";
import type { TServiceCode } from "app/types/app";
import DocumentWrapper from "widgets/wrappers/DocumentWrapper";
import PrintWrapper from "widgets/wrappers/PrintWrapper";
import ContractWrapper from "widgets/wrappers/ContractWrapper";

const DocumentPrintFormPage: FC = () => {
  const params = useParams();
  const serviceCode = params.serviceCode as TServiceCode;
  const status = params.status || "";

  return (
    <ContractWrapper>
      <DocumentWrapper>
        <PrintWrapper>
          <PageBox sx={{ position: "relative" }}>
            {Content.getPrint(serviceCode, status)}
          </PageBox>
        </PrintWrapper>
      </DocumentWrapper>
    </ContractWrapper>
  );
};

export default DocumentPrintFormPage;
