import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/UI/card";
import { Services } from "../config";

const TemplatesCard: FC = () => {
  const navigate = useNavigate();

  const clickHandler = (code: string) => navigate(`/templates/${code}`);

  return (
    <Card maxWidth="sm" title="Шаблоны">
      <List sx={{ mt: 2 }}>
        {Services.map((service, index) => (
          <ListItemButton
            key={service.code}
            onClick={() => clickHandler(service.code)}
          >
            <ListItemText
              primary={`${index + 1}. ${service.title}`}
              primaryTypographyProps={{ variant: "h5" }}
            />
          </ListItemButton>
        ))}
      </List>
    </Card>
  );
};

export default TemplatesCard;
