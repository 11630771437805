/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "shared/redux/store";
import type { TRange } from "app/types/document";
import FormatDate from "shared/lib/helpers/format/DateFormat";

interface DocumentsSearchbarState {
  range: TRange;
}

type TChangeRange = {
  name: keyof TRange;
  value: string;
};

const initialState: DocumentsSearchbarState = {
  range: {
    dateFrom: FormatDate.getDate("today"),
    dateTill: FormatDate.getDate("tomorrow"),
  },
};

export const documentsSearchbarSlice = createSlice({
  name: "documentsSearchbar",
  initialState,
  reducers: {
    changeRange: (state, action: PayloadAction<TChangeRange>) => {
      state.range[action.payload.name] = action.payload.value;
    },
  },
});

export const { changeRange } = documentsSearchbarSlice.actions;

export const searchbarSelector = (state: RootState) => state.documentsSearchbar;

export default documentsSearchbarSlice.reducer;
