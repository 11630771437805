import { useState, useEffect, forwardRef } from "react";
import { useParams } from "react-router-dom";
import CentralBox from "shared/UI/central-box";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import Content from "shared/lib/helpers/get-content/Content";
import type { TServiceCode } from "app/types/app";
import NotFound from "shared/UI/not-found";
import useJournals from "shared/lib/hooks/useJournals";
import JournalWrapper from "widgets/wrappers/JournalWrapper";

const sx = {
  centralBox: {
    minHeight: {
      xs: "calc(100vh - 19.5625rem)",
      sm: "calc(100vh - 16.5625rem)",
      md: "calc(100vh - 6rem)",
    },
  },
};

const JournalsTable = forwardRef<HTMLTableElement>((_, ref) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const params = useParams();
  const serviceCode = params.serviceCode as TServiceCode;
  const { data, isFetching } = useJournals();

  useEffect(() => {
    if (isFetching) {
      setIsReady(false);
    } else {
      setIsReady(true);
    }
  }, [isFetching]);

  if (!isReady)
    return (
      <CentralBox sx={sx.centralBox}>
        <FullSizeCircularProgress />
      </CentralBox>
    );

  return data?.length ? (
    <JournalWrapper>{Content.getJournal(serviceCode, ref)}</JournalWrapper>
  ) : (
    <NotFound sx={sx.centralBox} />
  );
});

export default JournalsTable;
