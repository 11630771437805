import type { FC } from "react";
import useContract from "shared/lib/hooks/useContract";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Card from "shared/UI/card";

const JournalsCard: FC = () => {
  const { data } = useContract();

  const params = useParams();
  const navigate = useNavigate();
  const id: string = params.contractId || "";
  const clickHandler = (code: string) => navigate(`/${id}/journals/${code}`);

  return (
    <Card title="Журналы" maxWidth="sm">
      <Box>
        <List sx={{ mt: 2 }}>
          {data?.services.map((category, index) => (
            <ListItemButton
              key={category.code}
              onClick={() => clickHandler(category.code)}
            >
              <ListItemText
                primary={`${index + 1}. ${category.name}`}
                primaryTypographyProps={{ variant: "h5" }}
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Card>
  );
};

export default JournalsCard;
