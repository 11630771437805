import Drawer from "@mui/material/Drawer";
import { type FC, useState } from "react";
import BurgerMenu from "features/burger-menu";
import Sidebar from "./Sidebar";

interface MobileNavbarProps {
  open: () => void;
}

const MobileNavbar: FC<MobileNavbarProps> = ({ open }) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  return (
    <>
      <BurgerMenu open={() => setSidebarOpen(true)} />
      <Drawer
        anchor="left"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        <Sidebar open={open} />
      </Drawer>
    </>
  );
};

export default MobileNavbar;
