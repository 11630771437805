import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";
import type { IDictionary, IDictionaryResponse } from "app/types/dictionary";
import type { TDocumentData } from "app/types/document";

const dictionaryAPI = createApi({
  reducerPath: "dictionaryAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    fetchDictionary: build.query<IDictionary[], TDocumentData<null>>({
      query: (data) => ({
        url: `/${data.serviceCode}/dictionary/${data.organizationGuid}`,
      }),
      transformResponse: (rawResult: IDictionaryResponse): IDictionary[] =>
        rawResult.dictionary,
    }),
  }),
});

export const { useFetchDictionaryQuery } = dictionaryAPI;
export const useFetchDictionaryQueryState =
  dictionaryAPI.endpoints.fetchDictionary.useQueryState;

export default dictionaryAPI;
