import type { TMaterial } from "entities/materials-table";
import type {
  IServiceDocument,
  IServiceDocumentRequest,
  IServiceDocumentResponse,
} from "app/types/document";

export default class ServiceFormat {
  static requestServiceFormat(
    service: string,
    data: IServiceDocument,
  ): IServiceDocumentRequest {
    const { patient, document, doctor, diagnosis, organization, headDoctor } =
      data;
    const address = {
      country: patient.address.country.name,
      countryCode: patient.address.country.code,
      region: patient.address.region.name,
      cityCode: patient.address.city.code,
      city: patient.address.city.name,
      street: patient.address.street,
      house: patient.address.house,
      flat: patient.address.flat,
    };
    const { child, ...other } = patient;
    switch (service) {
      case "histology":
        return {
          patient: {
            ...patient,
            address,
          },
          referral: {
            ...document,
            diagnosisName: diagnosis.name,
            diagnosisCode: diagnosis.code,
          },
          doctor,
          organization,
        };

      case "autopsy":
        return {
          patient: {
            ...other,
            address,
          },
          referral: {
            deathDate: document.deathDate,
            deathTime: document.deathTime,
            created: document.created,
            mobilePhone: document.mobilePhone,
            stationaryPhone: document.stationaryPhone,
          },
          child,
          sender: document.sender,
          doctor,
          headDoctor,
          organization,
        };

      default:
        return {
          patient: {
            ...patient,
            address,
          },
          referral: document,
          doctor,
          organization,
        };
    }
  }

  static responseServiceFormat(
    service: string,
    data: IServiceDocumentResponse,
  ): IServiceDocument {
    const {
      patient,
      doctor,
      referral,
      organization,
      headDoctor,
      child,
      sender,
    } = data;
    switch (service) {
      case "histology":
        return {
          doctor: {
            firstName: doctor.firstName,
            secondName: doctor.secondName,
            lastName: doctor.lastName,
          },
          headDoctor: {
            firstName: "",
            secondName: "",
            lastName: "",
          },
          diagnosis: {
            name: referral.diagnosisName || "",
            code: referral.diagnosisCode || "",
          },
          organization: {
            department: organization?.department || "",
          },
          patient: {
            firstName: patient.firstName,
            lastName: patient.lastName,
            secondName: patient.secondName || "",
            gender: patient.gender || "",
            personalNumber: patient.personalNumber || "",
            birthDate: patient.birthDate || "",
            placeWork: patient.placeWork || "",
            positionWork: patient.positionWork || "",
            address: {
              country: {
                name: patient.address.country || "",
                code: patient.address.countryCode || "",
              },
              region: {
                name: patient.address.region || "",
                code: patient.address.cityCode
                  ? patient.address.cityCode[0]
                  : "",
              },
              city: {
                name: patient.address.city || "",
                code: patient.address.cityCode || "",
              },
              street: patient.address.street || "",
              house: patient.address.house || "",
              flat: patient.address.flat || "",
              soato: patient.address.soato || "",
            },
            pet: {
              type: patient.pet?.type || "",
              name: patient.pet?.name || "",
              age: patient.pet?.age || "",
            },
          },
          document: {
            type: referral.type,
            cito: referral.cito,
            pay: referral.pay,
            sendTime: referral.sendTime,
            sendDate: referral.sendDate,
            samplingDate: referral.samplingDate,
            doctor: referral.doctor,
            materials: referral.materials.map((material: TMaterial) => ({
              number: material.number || "",
              materialType: material.materialType || "1",
              description: material.description || "",
              count: material.count || "",
            })),
            clinic: referral.clinic || "",
            method: referral.method,
          },
        };

      case "autopsy":
        return {
          doctor: {
            firstName: doctor.firstName,
            secondName: doctor.secondName,
            lastName: doctor.lastName,
          },
          headDoctor: {
            firstName: headDoctor?.firstName || "",
            secondName: headDoctor?.secondName || "",
            lastName: headDoctor?.lastName || "",
          },
          diagnosis: { name: "", code: "" },
          organization: {
            department: organization?.department || "",
          },
          patient: {
            firstName: patient.firstName,
            lastName: patient.lastName,
            secondName: patient.secondName || "",
            gender: patient.gender || "",
            personalNumber: patient.personalNumber || "",
            birthDate: patient.birthDate || "",
            placeWork: patient.placeWork || "",
            positionWork: patient.positionWork || "",
            address: {
              country: {
                name: patient.address.country || "",
                code: patient.address.countryCode || "",
              },
              region: {
                name: patient.address.region || "",
                code: patient.address.cityCode
                  ? patient.address.cityCode[0]
                  : "",
              },
              city: {
                name: patient.address.city || "",
                code: patient.address.cityCode || "",
              },
              street: patient.address.street || "",
              house: patient.address.house || "",
              flat: patient.address.flat || "",
              soato: patient.address.soato || "",
            },
            child: child
              ? {
                  firstName: child.firstName,
                  secondName: child.secondName,
                  lastName: child.lastName,
                  birthDate: child.birthday,
                  gender: child.gender,
                  personalNumber: child.personalNumber,
                }
              : null,
          },
          document: {
            deathDate: referral.deathDate,
            deathTime: referral.deathTime,
            created: referral.created,
            mobilePhone: referral.mobilePhone,
            stationaryPhone: referral.stationaryPhone,
            sender,
          },
        };

      default:
        return {
          doctor: {
            firstName: doctor.firstName,
            secondName: doctor.secondName,
            lastName: doctor.lastName,
          },
          headDoctor: {
            firstName: "",
            secondName: "",
            lastName: "",
          },
          diagnosis: {
            name: referral.diagnosisName || "",
            code: referral.diagnosisCode || "",
          },
          organization: {
            department: organization?.department || "",
          },
          patient: {
            firstName: patient.firstName,
            lastName: patient.lastName,
            secondName: patient.secondName || "",
            gender: patient.gender || "",
            personalNumber: patient.personalNumber || "",
            birthDate: patient.birthDate || "",
            placeWork: patient.placeWork || "",
            positionWork: patient.positionWork || "",
            address: {
              country: {
                name: patient.address.country || "",
                code: patient.address.countryCode || "",
              },
              region: {
                name: patient.address.region || "",
                code: patient.address.cityCode
                  ? patient.address.cityCode[0]
                  : "",
              },
              city: {
                name: patient.address.city || "",
                code: patient.address.cityCode || "",
              },
              street: patient.address.street || "",
              house: patient.address.house || "",
              flat: patient.address.flat || "",
              soato: patient.address.soato || "",
            },
          },
          document: referral,
        };
    }
  }
}
