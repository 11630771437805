import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, FC, useState, SyntheticEvent, useEffect } from "react";
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFormContext,
} from "react-hook-form";

import { fetchCities, fetchCountries } from "shared/api/referenceBookApi";
import useAppDispatch from "shared/lib/hooks/useAppDispatch";
import useDebounce from "shared/lib/hooks/useDebounce";
import type { TPatient } from "app/types/document";
import type { TAddress } from "app/types/referenceBook";
import { regions, TRegion } from "shared/lib/constants/regions";

const Address: FC = () => {
  const {
    control,
    setValue,
    getValues,
    formState: {
      errors: { patient },
    },
    clearErrors,
  } = useFormContext();
  const dispatch = useAppDispatch();
  const address = getValues("patient.address");
  const initialCountryState =
    address && address.country.name && address.country.code
      ? {
          CountryName: address.country.name,
          CountryCode: address.country.code,
        }
      : null;
  const [countryValue, setCountryValue] = useState<TAddress | null>(
    initialCountryState,
  );
  const initialRegionState =
    address && address.region.name && address.region.code
      ? { name: address.region.name, code: address.region.code }
      : null;
  const [regionValue, setRegionValue] = useState<TRegion | null>(
    initialRegionState,
  );
  const initialCityState =
    address && address.city.name && address.city.code
      ? { Name: address.city.name, Soato: address.city.code }
      : null;
  const [cityValue, setCityValue] = useState<TAddress | null>(initialCityState);

  const [countryArray, setCountryArray] = useState<TAddress[]>([]);
  const [regionsArray, setRegionsArray] = useState<TRegion[]>([]);
  const [cityArray, setCityArray] = useState<TAddress[]>([]);

  const getDebouncedCountries = useDebounce(async (country: string) => {
    if (country) {
      dispatch(fetchCountries(country)).then((res) => {
        if (res.data) setCountryArray(res.data);
      });
    }
  }, 500);

  const getDebouncedCities = useDebounce(async (city: string) => {
    if (city) {
      const region = getValues("patient.address.region.code");
      dispatch(fetchCities({ region, city })).then((res) => {
        if (res.data) setCityArray(res.data);
      });
    }
  }, 500);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "country":
        getDebouncedCountries(e.target.value);
        break;

      case "city":
        if (getValues("patient.address.region.code")) {
          getDebouncedCities(e.target.value);
        }
        break;

      default:
        break;
    }
  };

  const typedPatient: Merge<FieldError, FieldErrorsImpl<TPatient>> | undefined =
    patient;

  const RegionClearClick = () => {
    const container = document.getElementById("region-select")?.parentNode;
    const adornment = container?.querySelector(".MuiAutocomplete-endAdornment");
    const btn: HTMLElement | null | undefined = adornment?.querySelector(
      ".MuiAutocomplete-clearIndicator",
    );
    btn?.click();
  };

  const CityClearClick = () => {
    const container = document.getElementById("city-select")?.parentNode;
    const adornment = container?.querySelector(".MuiAutocomplete-endAdornment");
    const btn: HTMLElement | null | undefined = adornment?.querySelector(
      ".MuiAutocomplete-clearIndicator",
    );
    btn?.click();
  };

  const changeCountryHandler = (e: SyntheticEvent, value: TAddress | null) => {
    setCountryValue(value);
    setValue("patient.address.country", {
      name: value?.CountryName ? value.CountryName : "",
      code: value?.CountryCode ? value.CountryCode : "",
    });
    if (typedPatient?.address?.country?.name)
      clearErrors("patient.address.country.name");
  };

  useEffect(() => {
    if (countryValue?.CountryCode === "112") {
      setRegionsArray(regions);
    } else {
      clearErrors("patient.address.region.name");
      clearErrors("patient.address.city.name");
      setRegionsArray([]);
      setCityArray([]);
      RegionClearClick();
      CityClearClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue?.CountryCode]);

  const changeRegionHandler = (e: SyntheticEvent, value: TRegion | null) => {
    setRegionValue(value);
    setValue("patient.address.region", {
      name: value?.name ? value.name : "",
      code: value?.code ? value.code : "",
    });
    setCityArray([]);
    CityClearClick();
    if (typedPatient?.address?.region?.name)
      clearErrors("patient.address.region.name");
  };

  const changeCityHandler = (e: SyntheticEvent, value: TAddress | null) => {
    setCityValue(value);
    setValue("patient.address.city", {
      name: value?.Name ? value.Name : "",
      code: value?.Soato ? value.Soato : "",
    });
    if (typedPatient?.address?.city?.name)
      clearErrors("patient.address.city.name");
  };

  return (
    <Box>
      <Typography
        component="p"
        variant="subtitle1"
        sx={(theme) => ({
          fontWeight: "bold",
          color:
            theme.palette.mode === "light" ? "primary.main" : "text.primary",
        })}
      >
        Адрес
      </Typography>
      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={4}>
          <Autocomplete
            id="country-select"
            options={countryArray}
            noOptionsText="Введите название страны"
            autoHighlight
            size="small"
            onChange={changeCountryHandler}
            value={countryValue}
            getOptionLabel={(option) =>
              option.CountryName ? option.CountryName : ""
            }
            renderOption={(props, option) => {
              if (option && option.CountryName) {
                return (
                  <Box component="li" {...props}>
                    {option.CountryName}
                  </Box>
                );
              }
              return null;
            }}
            renderInput={(params) => (
              <TextField
                type="text"
                name="country"
                label="Страна"
                onChange={changeHandler}
                error={Boolean(typedPatient?.address?.country?.name)}
                helperText={typedPatient?.address?.country?.name?.message}
                {...params}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Autocomplete
            id="region-select"
            options={regionsArray}
            noOptionsText="Введите название области"
            autoHighlight
            size="small"
            onChange={changeRegionHandler}
            value={regionValue}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderOption={(props, option) => {
              if (option && option.name) {
                return (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                );
              }
              return null;
            }}
            renderInput={(params) => (
              <TextField
                type="text"
                name="region"
                label="Область"
                error={Boolean(typedPatient?.address?.region?.name)}
                helperText={typedPatient?.address?.region?.name?.message}
                {...params}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Autocomplete
            id="city-select"
            options={cityArray}
            autoHighlight
            noOptionsText="Введите название населённого пункта"
            size="small"
            onChange={changeCityHandler}
            value={cityValue}
            getOptionLabel={(option) => (option.Name ? option.Name : "")}
            renderOption={(props, option) => {
              if (option && option.Name) {
                return (
                  <Box component="li" {...props} key={option.Soato}>
                    {option.Name +
                      (option.Area ? `, ${option.Area} район` : "") +
                      (option.Council ? `, ${option.Council} сельсовет` : "")}
                  </Box>
                );
              }
              return null;
            }}
            renderInput={(params) => (
              <TextField
                type="text"
                onChange={changeHandler}
                name="city"
                label="Населённый пункт"
                error={Boolean(typedPatient?.address?.city?.name)}
                helperText={typedPatient?.address?.city?.name?.message}
                {...params}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Controller
            name="patient.address.street"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                label="Улица"
                inputProps={{
                  sx: { textTransform: "capitalize" },
                }}
                size="small"
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Controller
            name="patient.address.house"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                label="Дом"
                size="small"
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <Controller
            name="patient.address.flat"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                label="Квартира"
                size="small"
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Address;
