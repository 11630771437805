import { type FC, ReactElement, useEffect, useState } from "react";
import { useFetchInfoQuery, useFetchInfoQueryState } from "shared/api/authApi";
import PageBox from "shared/UI/page-box";
import FullSizeCircularProgress from "shared/UI/circle-loader";

type AuthWrapperProps = {
  children: ReactElement;
};

const AuthWrapper: FC<AuthWrapperProps> = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const token = localStorage.getItem("access_token");

  const { isLoading } = useFetchInfoQuery(null, {
    skip: Boolean(!token),
  });
  const { isLoading: isUserLoading } = useFetchInfoQueryState(null);

  useEffect(() => {
    setIsReady(!isLoading && !isUserLoading);
  }, [isLoading, isUserLoading]);

  if (isReady) return children;

  return (
    <PageBox>
      <FullSizeCircularProgress />
    </PageBox>
  );
};

export default AuthWrapper;
