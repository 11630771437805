/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "shared/redux/store";

interface UsersSearchbarState {
  searchValue: string;
  checkboxValue: boolean;
}

const initialState: UsersSearchbarState = {
  searchValue: "",
  checkboxValue: false,
};

export const usersSearchbarSlice = createSlice({
  name: "usersSearchbar",
  initialState,
  reducers: {
    changeSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    changeCheckboxValue: (state, action: PayloadAction<boolean>) => {
      state.checkboxValue = action.payload;
    },
  },
});

export const { changeCheckboxValue, changeSearchValue } =
  usersSearchbarSlice.actions;

export const searchbarSelector = (state: RootState) => state.usersSearchbar;

export default usersSearchbarSlice.reducer;
