import { Visibility, VisibilityOff } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import { useLoginMutation } from "pages/login";
import type { TLoginData } from "app/types/user";
import { useFetchInfoQueryState } from "shared/api/authApi";
import Msg from "shared/UI/error-message";
import LoginCard from "entities/login-card";

const LoginForm: FC = () => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm<TLoginData>({
    defaultValues: {
      login: "",
      password: "",
    },
  });
  const [isShown, setIsShown] = useState(false);

  const [login, { error, isLoading }] = useLoginMutation();
  const { isLoading: isUserLoading } = useFetchInfoQueryState(null);

  const onSubmitHandler: SubmitHandler<TLoginData> = (data) => {
    login(data);
  };

  useEffect(() => {
    if (error) toast.error(<Msg title="Ошибка авторизации!" message={error} />);
  }, [error]);

  useEffect(() => {
    if (isSubmitSuccessful) reset();
    // eslint-disable-next-line
  }, [isSubmitSuccessful]);

  const togglePassword = () => setIsShown((prevState) => !prevState);

  if (isLoading || isUserLoading) return <FullSizeCircularProgress />;

  return (
    <LoginCard>
      <Avatar sx={{ m: 1 }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h4" variant="h4" sx={{ fontWeight: "bold" }}>
        Войти
      </Typography>
      <Box
        component="form"
        aria-label="login-form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Controller
          name="login"
          control={control}
          rules={{ required: "Введите логин" }}
          render={({ field }) => (
            <TextField
              type="text"
              label="Логин"
              {...field}
              error={Boolean(errors.login)}
              helperText={errors.login && errors.login.message}
              margin="normal"
              fullWidth
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{ required: "Введите пароль" }}
          render={({ field }) => (
            <TextField
              type={isShown ? "text" : "password"}
              label="Пароль"
              {...field}
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password.message}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePassword} edge="end">
                      {isShown ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Войти
        </Button>
      </Box>
    </LoginCard>
  );
};

export default LoginForm;
