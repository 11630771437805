/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-underscore-dangle */
import PrintIcon from "@mui/icons-material/Print";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
  type FC,
  // useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
// import { useReactToPrint } from "react-to-print";

import type { Theme } from "@mui/material/styles";
import PageBox from "shared/UI/page-box";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import Content from "shared/lib/helpers/get-content/Content";
import type { TServiceCode } from "app/types/app";
import DocumentWrapper from "widgets/wrappers/DocumentWrapper";
import ContractWrapper from "widgets/wrappers/ContractWrapper";

const sx = {
  backdrop: {
    color: "#fff",
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
};

const DocumentViewPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [isPrinting, setIsPrinting] = useState<boolean>(false);

  // const componentRef = useRef() as any;
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   onBeforeGetContent: () => {
  //     setIsPrinting(true);
  //   },
  //   onAfterPrint: () => {
  //     setIsPrinting(false);
  //   },
  //   removeAfterPrint: true
  // });

  function closePrint(this: any) {
    document.body.removeChild(this.__container__);
    setIsLoading(false);
  }

  function setPrints(this: any) {
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = closePrint;
    this.contentWindow.onafterprint = closePrint;
  }

  const printPage = (url: string) => {
    setIsLoading(true);
    const hideFrame = document.createElement(`iframe`);
    hideFrame.id = `myIfame`;
    hideFrame.onload = setPrints;
    hideFrame.style.position = `fixed`;
    hideFrame.style.right = `0`;
    hideFrame.style.bottom = `0`;
    hideFrame.style.width = `0`;
    hideFrame.style.height = `0`;
    hideFrame.style.border = `0`;
    hideFrame.src = url;
    document.body.appendChild(hideFrame);
  };

  const params = useParams();
  const serviceCode = params.serviceCode as TServiceCode;
  const contractId: string = params.contractId || "";

  const status = params.status || "";
  const print = () =>
    printPage(
      `/${contractId}/${serviceCode}/print/${status}/${params.documentId}`,
    );

  return (
    <ContractWrapper>
      <DocumentWrapper>
        <PageBox>
          <Backdrop sx={sx.backdrop} open={isLoading}>
            <FullSizeCircularProgress />
          </Backdrop>
          <Container maxWidth="lg" sx={{ p: "0 !important" }}>
            <Paper elevation={1} sx={{ p: 4 }}>
              <Box
                // ref={componentRef}
                sx={{ m: 4 }}
              >
                {Content.getPrint(serviceCode, status)}
              </Box>
              <Grid
                container
                mt={1}
                spacing={1}
                columns={{ xs: 1, sm: 4, md: 6 }}
              >
                <Grid item xs sm={2} md={2} sx={{ ml: "auto" }}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    startIcon={<PrintIcon />}
                    fullWidth
                    onClick={print}
                  >
                    Печать
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </PageBox>
      </DocumentWrapper>
    </ContractWrapper>
  );
};

export default DocumentViewPage;
