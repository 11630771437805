import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  td: {
    border: "1px solid",
    borderColor: theme.palette.common.black,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
})) as typeof TableRow;

export default StyledTableRow;
