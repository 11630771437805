import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import { QRCodeSVG } from "qrcode.react";
import type { FC } from "react";
import useDocument from "shared/lib/hooks/useDocument";
import FormatDate from "shared/lib/helpers/format/DateFormat";
import useContract from "shared/lib/hooks/useContract";
// import OptionFormat from "../../../../helpers/format/OptionFormat";

const sx = {
  a4: {
    maxWidth: "21cm",
    margin: "auto",
    position: "relative",
  },
  paid: {
    position: "absolute",
    top: "3rem",
    width: 200,
  },
  typographyTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },
  signContainer: {
    display: "flex",
    alignItems: "end",
    gap: 1,
    justifyContent: "end",
    ml: "auto",
  },
};

const AutopsyReferral: FC = () => {
  const { data: documentData } = useDocument();
  const { data: contractData } = useContract();

  return (
    <Box sx={sx.a4}>
      <Grid container>
        <Grid item xs={11}>
          {/* <Typography variant="subtitle1" component="p">{referralReceivedData.organization.name}</Typography> */}
          {/* <Typography variant="subtitle1" component="p">{OptionFormat.returnOptionValue("department", referralReceivedData.organization.department, dictionaryReceivedData)}</Typography> */}
        </Grid>
        {/* <Grid item ml="auto" xs={1}> */}
        {/*    <QRCodeSVG value={guid} size={64} /> */}
        {/* </Grid> */}
      </Grid>
      <Typography variant="h5" component="h3" sx={sx.typographyTitle}>
        НАПРАВЛЕНИЕ
      </Typography>
      <Typography variant="h6" component="h4" sx={sx.typographyTitle}>
        НА ПАТОЛОГОАНАТОМИЧЕСКОЕ ВСКРЫТИЕ
      </Typography>
      <Typography variant="subtitle1" component="p">
        1. Наименование государственной организации здравоохранения, в которую
        направляется тело умершего, умершего новорожденного (далее – умерший),
        мертворожденного (плод): {contractData?.service?.name}
      </Typography>
      <Typography variant="subtitle1" component="p">
        2. Фамилия, собственное имя, отчество (если таковое имеется) умершего
        или матери мертворожденного (плода): {documentData?.patient.lastName}{" "}
        {documentData?.patient.firstName} {documentData?.patient.secondName}
      </Typography>
      <Typography variant="subtitle1" component="p">
        3. Число, месяц, год рождения умершего (мертворождения):{" "}
        {FormatDate.toNormalDate(documentData?.patient.birthDate || "")}
      </Typography>
      <Typography variant="subtitle1" component="p">
        4. Дата наступления смерти (мертворождения):{" "}
        {FormatDate.toNormalDate(documentData?.referral.deathDate)}
      </Typography>
      <Typography variant="subtitle1" component="p">
        5. Время наступления смерти (мертворождения):{" "}
        {documentData?.referral.deathTime || "—"}
      </Typography>
      <Typography variant="subtitle1" component="p">
        6. Дата направления на патологоанатомическое вскрытие:{" "}
        {FormatDate.toNormalDate(documentData?.referral.created.split(" ")[0])}
      </Typography>
      <Typography variant="subtitle1" component="p">
        7. Время направления на патологоанатомическое вскрытие:{" "}
        {documentData?.referral.created
          ? documentData?.referral.created.split(" ")[1]
          : "—"}
      </Typography>
      <Typography variant="subtitle1" component="p">
        8. Должность служащего, фамилия, инициалы медицинского работника,
        направившего умершего, мертворожденного (плод) на патологоанатомическое
        вскрытие: {documentData?.sender?.positionWork}{" "}
        {documentData?.sender?.lastName} {documentData?.sender?.firstName}{" "}
        {documentData?.sender?.secondName}
      </Typography>
      <Typography variant="subtitle1" component="p">
        9. Контактный телефон: моб.{documentData?.referral.mobilePhone} дом.
        {documentData?.referral.stationaryPhone}
      </Typography>
      <Grid container spacing={1} mt={1} sx={{ pageBreakInside: "avoid" }}>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="p">
            Врач-специалист (фельдшер, помощник врача) организации, направившей
            умершего, мертворожденного (плод) на патологоанатомическое вскрытие
          </Typography>
        </Grid>
        <Grid item container xs={8} sx={sx.signContainer}>
          <Grid
            item
            xs={3}
            sx={{ textAlign: "center", ml: "auto", mt: "auto" }}
          >
            <Box sx={{ borderTop: "1px solid" }}>
              <Typography variant="subtitle2" component="p">
                (подпись)
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "center", ml: 1, mt: "auto" }}>
            <Typography variant="subtitle1" component="p">
              {documentData?.doctor.lastName} {documentData?.doctor.firstName}{" "}
              {documentData?.doctor.secondName}
            </Typography>
            <Box sx={{ borderTop: "1px solid" }}>
              <Typography variant="subtitle2" component="p">
                (фамилия, инициалы)
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1} sx={{ pageBreakInside: "avoid" }}>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="p">
            Руководитель (заместитель руководителя) организации, направившей
            умершего, мертворожденного (плод) на патологоанатомическое вскрытие
          </Typography>
        </Grid>
        <Grid item container xs={8} sx={sx.signContainer}>
          <Grid
            item
            xs={3}
            sx={{ textAlign: "center", ml: "auto", mt: "auto" }}
          >
            <Box sx={{ borderTop: "1px solid" }}>
              <Typography variant="subtitle2" component="p">
                (подпись)
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "center", ml: 1, mt: "auto" }}>
            <Typography variant="subtitle1" component="p">
              {documentData?.headDoctor?.lastName}{" "}
              {documentData?.headDoctor?.firstName}{" "}
              {documentData?.headDoctor?.secondName}
            </Typography>
            <Box sx={{ borderTop: "1px solid" }}>
              <Typography variant="subtitle2" component="p">
                (фамилия, инициалы)
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AutopsyReferral;
