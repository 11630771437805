import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useDeleteModalContext } from "shared/lib/context/DeleteModal";
import useDictionary from "shared/lib/hooks/useDictionary";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import StyledTableCell from "shared/UI/table/table-cell";
import StyledTableRow from "shared/UI/table/table-row";
import type { TMaterial } from "./MaterialsTable";

interface IProps {
  material: TMaterial;
  number: number;
  editClick: (index: number, material: TMaterial) => void;
}

const MaterialsTableItem: FC<IProps> = (props) => {
  const { material, number, editClick } = props;
  const { setValue, getValues } = useFormContext();
  const context = useDeleteModalContext();
  const dictionary = useDictionary();

  const removeMaterial = (id: string) => {
    setValue("document.materials", [
      ...getValues("document.materials").filter(
        (item: TMaterial) =>
          item.count + item.description + item.number + item.materialType !==
          id,
      ),
    ]);
  };

  const handleDeleteClick = (id: string) => {
    if (context) context.open(() => removeMaterial(id));
  };

  return (
    <StyledTableRow>
      <StyledTableCell align="center">{number}</StyledTableCell>
      <StyledTableCell align="center">{material.number}</StyledTableCell>
      <StyledTableCell align="center">
        {OptionFormat.returnOptionValue(
          "material",
          material.materialType,
          dictionary.data,
        )}
      </StyledTableCell>
      <StyledTableCell align="center">{material.description}</StyledTableCell>
      <StyledTableCell align="center">{material.count}</StyledTableCell>
      <StyledTableCell align="center">
        <IconButton onClick={() => editClick(number, material)}>
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            handleDeleteClick(
              material.count +
                material.description +
                material.number +
                material.materialType,
            )
          }
        >
          <DeleteIcon color="error" />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default MaterialsTableItem;
