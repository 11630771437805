import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "shared/api/baseQueryWithReauth";
import type {
  IDocumentMutation,
  IServiceDocumentRequest,
  IServiceDocumentResponse,
  IDocumentListResponse,
  TRange,
  TDocumentData,
} from "app/types/document";

export const documentAPI = createApi({
  reducerPath: "documentAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Documents"],
  endpoints: (build) => ({
    fetchDocuments: build.query<IDocumentListResponse, TDocumentData<TRange>>({
      query: (data) => ({
        url: `/${data.serviceCode}/list/${data.organizationGuid}`,
        params: data.payload,
      }),
      providesTags: () => ["Documents"],
    }),
    fetchDocument: build.query<IServiceDocumentResponse, TDocumentData<null>>({
      query: (data) => ({
        url:
          data.status === "result"
            ? `/${data.serviceCode}/read/${data.organizationGuid}/${data.guid}?ready=1`
            : `/${data.serviceCode}/read/${data.organizationGuid}/${data.guid}`,
      }),
      transformResponse: (rawResult: {
        histology: IServiceDocumentResponse;
      }): IServiceDocumentResponse => rawResult.histology,
      providesTags: () => ["Documents"],
    }),
    createDocument: build.mutation<
      IDocumentMutation,
      TDocumentData<IServiceDocumentRequest>
    >({
      query: (data) => ({
        url: `/${data.serviceCode}/create/${data.organizationGuid}`,
        method: "POST",
        body: data.payload,
      }),
      invalidatesTags: ["Documents"],
    }),
    editDocument: build.mutation<
      IDocumentMutation,
      TDocumentData<IServiceDocumentRequest>
    >({
      query: (data) => ({
        url: `/${data.serviceCode}/edit/${data.organizationGuid}/${data.guid}`,
        method: "PUT",
        body: data.payload,
      }),
      invalidatesTags: ["Documents"],
    }),
    deleteDocument: build.mutation<IDocumentMutation, TDocumentData<null>>({
      query: (data) => ({
        url: `/${data.serviceCode}/delete/${data.organizationGuid}/${data.guid}`,
        method: "DELETE",
      }),
      invalidatesTags: [`Documents`],
    }),
  }),
});

export const {
  useFetchDocumentsQuery,
  useFetchDocumentQuery,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useEditDocumentMutation,
} = documentAPI;

// export default documentAPI;
