import Box from "@mui/material/Box";
import type { FC } from "react";
import Logo from "shared/UI/logo";
import LoginForm from "features/login-form";
import LoginWrapperBox from "./LoginWrapperBox";

const sx = {
  logoWrapper: {
    position: "absolute",
    right: "1rem",
    bottom: "1rem",
  },
};

const LoginPage: FC = () => {
  return (
    <LoginWrapperBox>
      <LoginForm />
      <Box sx={sx.logoWrapper}>
        <Logo />
      </Box>
    </LoginWrapperBox>
  );
};

export default LoginPage;
