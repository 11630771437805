import type { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import type { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

const sx = {
  typography: {
    fontWeight: "bold",
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
  capitalize: {
    textTransform: "capitalize",
  },
};

const Employee: FC = () => {
  const { control } = useFormContext();
  return (
    <Box>
      <Typography component="p" variant="subtitle1" sx={sx.typography}>
        Медицинскй работник
      </Typography>
      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={2} sm={4} md={3}>
          <Controller
            name="document.sender.positionWork"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                label="Должность"
                size="small"
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={3}>
          <Controller
            name="document.sender.lastName"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                label="Фамилия"
                size="small"
                inputProps={{ sx: sx.capitalize }}
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={3}>
          <Controller
            name="document.sender.firstName"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                label="Имя"
                size="small"
                inputProps={{ sx: sx.capitalize }}
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={4} md={3}>
          <Controller
            name="document.sender.secondName"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                label="Отчество"
                size="small"
                inputProps={{ sx: sx.capitalize }}
                {...field}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Employee;
