import { useParams } from "react-router-dom";
import { useFetchDocumentQuery } from "pages/[service]/home";
import type { TServiceCode } from "app/types/app";
import type { TOrganization } from "app/types/contract";
import useContract from "./useContract";

const useDocument = () => {
  const params = useParams();
  const serviceCode = params.serviceCode as TServiceCode;
  const guid = params.documentId || "";
  const status = params.status || "";
  const { data: contractData } = useContract();
  const organizationGuid: TOrganization["guid"] =
    contractData?.service?.guid || "";

  const documentData = {
    serviceCode,
    organizationGuid,
    guid,
    status,
  };
  const documentOptions = {
    skip: !contractData || !guid,
  };
  const document = useFetchDocumentQuery(documentData, documentOptions);

  return document;
};

export default useDocument;
