import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchInfo } from "shared/api/authApi";
import type { TLoginData, TokenResponse } from "app/types/user";

const getLoginHeaders = ({ login, password }: TLoginData) => ({
  Authorization: `Basic ${btoa(
    unescape(encodeURIComponent(`${login}:${password}`)),
  )}`,
});

const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;

export const loginAPI = createApi({
  reducerPath: "loginAPI",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: (build) => ({
    login: build.mutation<TokenResponse, TLoginData>({
      query: (data) => ({
        url: "/authorization/user",
        method: "POST",
        headers: getLoginHeaders(data),
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem("access_token", data.access_token);
          localStorage.setItem("refresh_token", data.refresh_token);
          await dispatch(fetchInfo(null));
        } catch (error) {
          await Promise.reject(error);
        }
      },
    }),
  }),
});

export const { useLoginMutation } = loginAPI;
