import { combineReducers, Reducer } from "@reduxjs/toolkit";

import themeReducer from "./theme/ThemeSlice";
import userReducer from "./user/UserSlice";
import usersSearchbarReducer from "pages/admin/users/home/redux/SearchbarSlice";
import contractsSearchbarReducer from "pages/admin/contracts/home/redux/SearchbarSlice";
import documentsSearchbarReducer from "pages/[service]/home/redux/SearchbarSlice";
import journalsSearchbarReducer from "pages/journals/[service]/redux/SearchbarSlice";

import refreshAPI from "shared/api/refreshApi";
import authAPI from "shared/api/authApi";
import contractAPI from "shared/api/contractApi";
import dictionaryAPI from "shared/api/dictionaryApi";
import { documentAPI } from "pages/[service]/home";
import { journalAPI } from "pages/journals/[service]";
import { loginAPI } from "pages/login";
import { feedbackAPI } from "pages/feedback";
import { templateAPI } from "pages/templates/[service]";
import { diagnosisAPI } from "widgets/diagnosis";
import referenceBookAPI from "shared/api/referenceBookApi";
import adminOrganizationAPI from "pages/admin/organization/api/adminOrganizationApi";
import adminUsersAPI from "pages/admin/users/api/adminUsersApi";
import adminContractsAPI from "pages/admin/contracts/api/adminContractsApi";

const combinedReducer = combineReducers({
  user: userReducer,
  theme: themeReducer,
  usersSearchbar: usersSearchbarReducer,
  contractsSearchbar: contractsSearchbarReducer,
  documentsSearchbar: documentsSearchbarReducer,
  journalsSearchbar: journalsSearchbarReducer,
  [refreshAPI.reducerPath]: refreshAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [loginAPI.reducerPath]: loginAPI.reducer,
  [templateAPI.reducerPath]: templateAPI.reducer,
  [diagnosisAPI.reducerPath]: diagnosisAPI.reducer,
  [dictionaryAPI.reducerPath]: dictionaryAPI.reducer,
  [feedbackAPI.reducerPath]: feedbackAPI.reducer,
  [contractAPI.reducerPath]: contractAPI.reducer,
  [documentAPI.reducerPath]: documentAPI.reducer,
  [referenceBookAPI.reducerPath]: referenceBookAPI.reducer,
  [adminContractsAPI.reducerPath]: adminContractsAPI.reducer,
  [adminOrganizationAPI.reducerPath]: adminOrganizationAPI.reducer,
  [adminUsersAPI.reducerPath]: adminUsersAPI.reducer,
  [journalAPI.reducerPath]: journalAPI.reducer,
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === "user/logout") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
