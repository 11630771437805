import { useMemo } from "react";
import useAppSelector from "./useAppSelector";
import userSelector from "shared/redux/user/UserSelector";

const useRoles = () => {
  const { user } = useAppSelector(userSelector);

  const isAdmin: boolean = useMemo(
    () => user?.roles.includes("admin"),
    [user?.roles],
  );
  const isDoc: boolean = useMemo(
    () => user?.roles.includes("doc"),
    [user?.roles],
  );
  const isReader: boolean = useMemo(
    () => user?.roles.includes("reader"),
    [user?.roles],
  );

  return useMemo(
    () => ({ isAdmin, isDoc, isReader }),
    [isAdmin, isDoc, isReader],
  );
};

export default useRoles;
