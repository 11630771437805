import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { FC, ReactNode, useLayoutEffect, useState } from "react";
import type { IContract } from "app/types/contract";
import FormatDate from "shared/lib/helpers/format/DateFormat";

const sx = {
  card: {
    width: {
      xs: "100%",
      sm: "45%",
      xl: "30%",
    },
    minWidth: "14rem",
  },
  media: {
    height: 200,
  },
  typography: {
    fontSize: {
      xs: "1rem",
      md: "1.5rem",
    },
  },
};

interface ContractCardProps {
  contract: IContract;
  actions: ReactNode;
}

const ContractCard: FC<ContractCardProps> = ({ contract, actions }) => {
  const [logo, setLogo] = useState<string>("");
  const { id, started, ended, organization, organizationName } = contract;

  useLayoutEffect(() => {
    try {
      // eslint-disable-next-line global-require, import/no-dynamic-require, @typescript-eslint/no-var-requires
      setLogo(require(`shared/lib/assets/logos/${organization}.png`));
    } catch (err) {
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      setLogo(require("shared/lib/assets/logos/placeholder.jpg"));
    }
  }, [organization]);

  return (
    <Card sx={sx.card} key={id}>
      <CardMedia
        component="img"
        alt="organization logo"
        sx={sx.media}
        image={logo}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="p" sx={sx.typography}>
          {organizationName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          С {FormatDate.toNormalDate(new Date(started))}{" "}
          {ended && `по ${FormatDate.toNormalDate(new Date(ended))}`}
        </Typography>
      </CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
};

export default ContractCard;
