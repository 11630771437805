import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import type { Theme } from "@mui/material/styles";
import Doctor from "entities/doctor";
import Patient from "entities/patient";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import useDocument from "shared/lib/hooks/useDocument";
import type { IServiceDocument } from "app/types/document";
import ServiceFormat from "shared/lib/helpers/format/ServiceFormat";
import Content from "shared/lib/helpers/get-content/Content";
import type { TServiceCode } from "app/types/app";
import useContract from "shared/lib/hooks/useContract";
import type { TOrganization } from "app/types/contract";
import {
  useCreateDocumentMutation,
  useEditDocumentMutation,
} from "pages/[service]/home";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import userSelector from "shared/redux/user/UserSelector";
import getFormDefaultValues from "../helpers/getFormDefaultValues";
import Msg from "shared/UI/error-message";

const sx = {
  backdrop: {
    color: "#fff",
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
};

const DocumentForm: FC = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const params = useParams();
  const navigate = useNavigate();
  const serviceCode = params.serviceCode as TServiceCode;
  const guid = params.documentId || "";
  const contractId: string = params.contractId || "";
  const { data: contractData } = useContract();
  const organizationGuid: TOrganization["guid"] =
    contractData?.service?.guid || "";
  const { user } = useAppSelector(userSelector);
  const methods = useForm<IServiceDocument>(getFormDefaultValues(user));
  const { data: documentData, isLoading } = useDocument();

  const [createDocument, createDocumentResult] = useCreateDocumentMutation();
  const [editDocument, editDocumentResult] = useEditDocumentMutation();

  const onSubmitHandler = (data: IServiceDocument) => {
    const serviceData = ServiceFormat.requestServiceFormat(serviceCode, data);
    if (guid) {
      editDocument({
        serviceCode,
        organizationGuid,
        guid,
        payload: serviceData,
      });
    } else {
      createDocument({ serviceCode, organizationGuid, payload: serviceData });
    }
  };

  const clickHandler = () => {
    if (!methods.getValues("patient.address.country.name")) {
      methods.setError("patient.address.country.name", {
        type: "required",
        message: "Введите название страны",
      });
    }
    if (
      methods.getValues("patient.address.country.code") === "112" &&
      !methods.getValues("patient.address.region.name")
    ) {
      methods.setError("patient.address.region.name", {
        type: "required",
        message: "Введите название области",
      });
    }
    if (
      methods.getValues("patient.address.country.code") === "112" &&
      !methods.getValues("patient.address.city.name")
    ) {
      methods.setError("patient.address.city.name", {
        type: "required",
        message: "Введите название населенного пункта",
      });
    }
  };

  useLayoutEffect(() => {
    if (documentData) {
      const serviceData = ServiceFormat.responseServiceFormat(
        serviceCode,
        documentData,
      );
      if (serviceData) methods.reset(serviceData);
    }
    setIsReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentData]);

  useEffect(() => {
    if (createDocumentResult.error)
      toast.error(
        <Msg
          title="Ошибка создания записи!"
          message={createDocumentResult.error}
        />,
      );
  }, [createDocumentResult.error]);

  useEffect(() => {
    if (createDocumentResult.isSuccess) {
      toast.success("Запись успешно создана!");
      navigate(`/${contractId}/${serviceCode}`);
    }
    // eslint-disable-next-line
  }, [createDocumentResult.isSuccess]);

  useEffect(() => {
    if (editDocumentResult.error)
      toast.error(
        <Msg
          title="Ошибка редактирования записи!"
          message={editDocumentResult.error}
        />,
      );
  }, [editDocumentResult.error]);

  useEffect(() => {
    if (editDocumentResult.isSuccess) {
      toast.success("Запись успешно отредактирована!");
      navigate(`/${contractId}/${serviceCode}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDocumentResult.isSuccess]);

  if (isLoading) return <FullSizeCircularProgress />;

  if (isReady)
    return (
      <Box component="form" onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <Backdrop
          sx={sx.backdrop}
          open={createDocumentResult.isLoading || editDocumentResult.isLoading}
        >
          <FullSizeCircularProgress />
        </Backdrop>
        <FormProvider {...methods}>
          <Patient />
          {serviceCode && Content.getInputForm(serviceCode)}
          {serviceCode && Content.getDiagnosis(serviceCode)}
          <Doctor />
          {serviceCode && Content.getHeadDoctor(serviceCode)}
          <Grid container mt={1} spacing={1} columns={{ xs: 1, sm: 4, md: 6 }}>
            <Grid item xs sm={2} md={2} sx={{ ml: "auto" }}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                fullWidth
                onClick={clickHandler}
              >
                {guid ? "Редактировать" : "Создать"}
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    );
  return null;
};

export default DocumentForm;
