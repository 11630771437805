export const Services = [
  {
    code: "histology",
    title: "Гистология",
  },
  {
    code: "autopsy",
    title: "Вскрытие",
  },
];
