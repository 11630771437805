import type { FC } from "react";
import type { IContract } from "app/types/contract";
import ContractListWrapper from "./ContractListWrapper";
import ContractCard from "entities/contract-card";
import CardLinks from "features/card-links";

interface ContractListProps {
  contracts: IContract[];
}

const ContractList: FC<ContractListProps> = ({ contracts }) => (
  <ContractListWrapper>
    {contracts.map((contract) => (
      <ContractCard
        contract={contract}
        key={contract.id}
        actions={<CardLinks contract={contract} />}
      />
    ))}
  </ContractListWrapper>
);

export default ContractList;
