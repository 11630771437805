import type { SelectChangeEvent } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { ChangeEvent, FC, useMemo } from "react";
import useDictionary from "shared/lib/hooks/useDictionary";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import {
  changeDepartment,
  changeRange,
  searchbarSelector,
} from "../../../pages/journals/[service]/redux/SearchbarSlice";
import useAppDispatch from "shared/lib/hooks/useAppDispatch";
import type { TRange } from "app/types/document";

interface JournalsSearchbarProps {
  handlePrint: () => void;
}

const JournalsSearchbar: FC<JournalsSearchbarProps> = ({ handlePrint }) => {
  const dictionary = useDictionary();
  const dispatch = useAppDispatch();
  const { range, department } = useAppSelector(searchbarSelector);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      changeRange({
        name: e.target.name as keyof TRange,
        value: e.target.value,
      }),
    );
  const changeDepartmentHandler = (e: SelectChangeEvent) =>
    dispatch(changeDepartment(e.target.value));

  const departmentList = useMemo(
    () => OptionFormat.getOption("department", dictionary.data),
    [dictionary.data],
  );

  return (
    <Paper elevation={2}>
      <Box sx={{ p: ".75rem" }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} md={3}>
            <TextField
              type="date"
              name="dateFrom"
              value={range.dateFrom}
              onChange={changeHandler}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              type="date"
              name="dateTill"
              value={range.dateTill}
              onChange={changeHandler}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <FormControl fullWidth>
              <InputLabel size="small" id="department-label">
                Отделение
              </InputLabel>
              <Select
                onChange={changeDepartmentHandler}
                value={department}
                labelId="department-label"
                label="Отделение"
                size="small"
              >
                <MenuItem value="0">— Выберите отделение —</MenuItem>
                {departmentList &&
                  departmentList.map((departmentItem) => (
                    <MenuItem
                      key={departmentItem.value}
                      value={departmentItem.value}
                    >
                      {departmentItem.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={3} sx={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              onClick={handlePrint}
              color="primary"
              fullWidth
              startIcon={<PrintIcon />}
            >
              Печать
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default JournalsSearchbar;
