import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import type { FC } from "react";
import type { Theme } from "@mui/material";

const sx = {
  typography: {
    fontWeight: "bold",
    textAlign: "center",
    mb: 3,
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
};

interface FeedbackSuccessfulMessageProps {
  close: () => void;
}

export const FeedbackSuccessfulMessage: FC<FeedbackSuccessfulMessageProps> = ({
  close,
}) => {
  return (
    <Paper elevation={1} sx={{ p: 7 }}>
      <Typography component="h5" variant="h4" sx={sx.typography}>
        Спасибо за Вашу обратную связь!
      </Typography>
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Button size="large" variant="contained" onClick={close} sx={{ mt: 2 }}>
          Закрыть
        </Button>
      </Box>
    </Paper>
  );
};
