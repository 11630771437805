/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-underscore-dangle */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PreviewIcon from "@mui/icons-material/Preview";
import PrintIcon from "@mui/icons-material/Print";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import Backdrop from "@mui/material/Backdrop";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Typography, type PaperProps } from "@mui/material";
import { useFetchContractQuery } from "shared/api/contractApi";
import { useDeleteDocumentMutation } from "pages/[service]/home";
import { useDeleteModalContext } from "shared/lib/context/DeleteModal";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import type { TServiceCode } from "app/types/app";
import type { TOrganization } from "app/types/contract";
import type { Data } from "app/types/document";
import Msg from "shared/UI/error-message";
import useRoles from "shared/lib/hooks/useRoles";

interface DocumentMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  PaperProps: PaperProps;
  transformOrigin: {
    horizontal: "center" | "left" | "right" | number;
    vertical: "bottom" | "center" | "top" | number;
  };
  anchorOrigin: {
    horizontal: "center" | "left" | "right" | number;
    vertical: "bottom" | "center" | "top" | number;
  };
  row: Data | null;
}

const DocumentMenu: FC<DocumentMenuProps> = (props) => {
  const { isAdmin, isDoc } = useRoles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { row, ...menuProps } = props;
  const navigate = useNavigate();
  const params = useParams();
  const context = useDeleteModalContext();
  const [deleteDocument, deleteOptions] = useDeleteDocumentMutation();

  const id: string = params.contractId || "";
  const serviceCode = params.serviceCode as TServiceCode;
  const contract = useFetchContractQuery(id);
  const organizationGuid: TOrganization["guid"] =
    contract.data?.service?.guid || "";

  const handleDeleteClick = () => {
    if (context) {
      const data = {
        guid: row?.guid,
        serviceCode,
        organizationGuid,
      };
      context.open(() => deleteDocument(data));
    }
  };

  function closePrint(this: any) {
    document.body.removeChild(this.__container__);
    setIsLoading(false);
  }

  function setPrints(this: any) {
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = closePrint;
    this.contentWindow.onafterprint = closePrint;
  }

  const printPage = (url: string) => {
    setIsLoading(true);
    const hideFrame = document.createElement("iframe");
    hideFrame.id = "myIfame";
    hideFrame.onload = setPrints;
    hideFrame.style.position = "fixed";
    hideFrame.style.right = "0";
    hideFrame.style.bottom = "0";
    hideFrame.style.width = "0";
    hideFrame.style.height = "0";
    hideFrame.style.border = "0";
    hideFrame.src = url;
    document.body.appendChild(hideFrame);
  };

  const view = (status: string) =>
    navigate(
      `/${params.contractId}/${params.serviceCode}/view/${status}/${row?.guid}`,
    );
  const edit = () =>
    navigate(`/${params.contractId}/${params.serviceCode}/edit/${row?.guid}`);
  const print = (status: string) =>
    printPage(
      `/${params.contractId}/${params.serviceCode}/print/${status}/${row?.guid}`,
    );

  useEffect(() => {
    if (deleteOptions.isSuccess) {
      toast.success("Запись успешно удалена!");
    }
  }, [deleteOptions.isSuccess]);

  useEffect(() => {
    if (deleteOptions.error)
      toast.error(
        <Msg title="Ошибка удаления записи!" message={deleteOptions.error} />,
      );
  }, [deleteOptions.error]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || deleteOptions.isLoading}
      >
        <FullSizeCircularProgress />
      </Backdrop>
      <Menu {...menuProps}>
        <MenuList>
          <MenuItem onClick={() => view("referral")}>
            <ListItemIcon>
              <PreviewIcon />
            </ListItemIcon>{" "}
            Просмотр направления
          </MenuItem>
          {row?.status === "received" && (isAdmin || isDoc) && (
            <MenuItem onClick={edit}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>{" "}
              Редактировать
            </MenuItem>
          )}
          <MenuItem onClick={() => print("referral")}>
            <ListItemIcon>
              <PrintIcon />
            </ListItemIcon>{" "}
            Печать направления
          </MenuItem>
          <MenuItem disabled onClick={() => {}}>
            <ListItemIcon>
              <QrCode2Icon />
            </ListItemIcon>{" "}
            QR-Code
          </MenuItem>
          {row?.status === "complite" && (
            <MenuItem onClick={() => view("result")}>
              <ListItemIcon>
                <PreviewIcon />
              </ListItemIcon>{" "}
              Просмотр заключения
            </MenuItem>
          )}
          {row?.status === "complite" && (
            <MenuItem onClick={() => print("result")}>
              <ListItemIcon>
                <PrintIcon />
              </ListItemIcon>{" "}
              Печать заключения
            </MenuItem>
          )}
          {row?.status === "received" && (isAdmin || isDoc) && (
            <MenuItem onClick={handleDeleteClick}>
              <ListItemIcon>
                <DeleteIcon color="error" />
              </ListItemIcon>{" "}
              <Typography color="error">Удалить</Typography>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default DocumentMenu;
