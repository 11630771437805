import useMediaQuery from "@mui/material/useMediaQuery";
import { type FC, useState } from "react";
import Box from "@mui/material/Box";
import LogoutModal from "shared/logout-modal";
import Sidebar from "./Sidebar";
import MobileNavbar from "./MobileNavbar";
import { sidebarWidth } from "widgets/main-container";

const sx = {
  container: {
    "@media print": {
      display: "none",
    },
  },
  aside: {
    position: "fixed",
    left: 0,
    overflow: "hidden",
    zIndex: "10",
    width: sidebarWidth,
  },
};

const Navbar: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const isMobile = useMediaQuery("(max-width:899px)");

  const openSidebar = () => setOpen(true);

  return (
    <Box sx={sx.container}>
      {isMobile ? (
        <MobileNavbar open={openSidebar} />
      ) : (
        <Box component="aside" sx={sx.aside}>
          <Sidebar open={openSidebar} />
        </Box>
      )}
      <LogoutModal isOpen={open} close={() => setOpen(false)} />
    </Box>
  );
};

export default Navbar;
