import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import TemplateModalContextProvider from "shared/lib/context/TemplateModal/provider";
import DocumentWrapper from "widgets/wrappers/DocumentWrapper";
import DocumentForm from "features/document-form";
import ContractWrapper from "widgets/wrappers/ContractWrapper";

const sx = {
  container: {
    p: "0 !important",
  },
  paper: {
    p: {
      xs: 2,
      md: 4,
    },
  },
};

const DocumentInputFormPage: FC = () => {
  return (
    <ContractWrapper>
      <DocumentWrapper>
        <TemplateModalContextProvider>
          <PageBox>
            <Container maxWidth="lg" sx={sx.container}>
              <Paper elevation={2} sx={sx.paper}>
                <DocumentForm />
              </Paper>
            </Container>
          </PageBox>
        </TemplateModalContextProvider>
      </DocumentWrapper>
    </ContractWrapper>
  );
};

export default DocumentInputFormPage;
