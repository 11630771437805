import type { FC, ReactElement } from "react";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import useDocument from "shared/lib/hooks/useDocument";
import useDictionary from "shared/lib/hooks/useDictionary";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Msg from "shared/UI/error-message";

interface DocumentWrapperProps {
  children: ReactElement;
}

const DocumentWrapper: FC<DocumentWrapperProps> = ({ children }) => {
  const dictionary = useDictionary();
  const document = useDocument();

  useEffect(() => {
    if (dictionary.error)
      toast.error(
        <Msg title="Ошибка получения словаря!" message={dictionary.error} />,
      );
  }, [dictionary.error]);

  useEffect(() => {
    if (document.error)
      toast.error(
        <Msg title="Ошибка получения записи!" message={document.error} />,
      );
  }, [document.error]);

  if (dictionary.isLoading || document.isLoading)
    return <FullSizeCircularProgress />;
  if (
    !dictionary.isLoading &&
    !dictionary.isError &&
    !document.isLoading &&
    !document.isError
  )
    return children;
  return null;
};

export default DocumentWrapper;
