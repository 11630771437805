import { type FC, ReactElement, useEffect } from "react";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import useDocument from "shared/lib/hooks/useDocument";
import { toast } from "react-toastify";
import Msg from "shared/UI/error-message";

interface PrintWrapperProps {
  children: ReactElement;
}

const PrintWrapper: FC<PrintWrapperProps> = ({ children }) => {
  const document = useDocument();

  useEffect(() => {
    if (document.error)
      toast.error(
        <Msg title="Ошибка получения записи!" message={document.error} />,
      );
  }, [document.error]);

  useEffect(() => {
    if (document.isSuccess) window.print();
  }, [document.isSuccess]);
  if (document.isLoading) return <FullSizeCircularProgress />;
  if (document.isSuccess) return children;
  return null;
};

export default PrintWrapper;
