import type { HeadCell } from "app/types/document";

export const HeadCells: readonly HeadCell[] = [
  {
    id: "fio",
    label: "ФИО",
  },
  {
    id: "birthDate",
    label: "Дата рождения",
  },
  {
    id: "dateCreate",
    label: "Дата создания направления",
  },
];
