import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { Toolbar } from "@mui/material";
import type { Theme } from "@mui/material";
import type { FC } from "react";

const sx = {
  appBar: {
    bgcolor: (theme: Theme) =>
      theme.palette.mode === "light"
        ? "rgba(241, 241, 241, 0.7);"
        : "rgba(20, 27, 45, 0.7);",
    boxShadow: "none",
    backdropFilter: "blur(.5rem)",
  },
};

interface BurgerMenuProps {
  open: () => void;
}

const BurgerMenu: FC<BurgerMenuProps> = ({ open }) => {
  return (
    <AppBar component="header" position="fixed" color="default" sx={sx.appBar}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={open}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default BurgerMenu;
