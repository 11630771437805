import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from "@mui/utils";
import type { ChangeEvent, FC, MouseEvent } from "react";
import type { Data, Order } from "app/types/document";
import { HeadCells } from "../config";

interface TableHeadProps {
  numSelected: number;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const DocumentsTableHead: FC<TableHeadProps> = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" padding="normal">
          №
        </TableCell>
        {HeadCells.map((headCell) => (
          <TableCell
            align="left"
            padding="normal"
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : `asc`}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="left" padding="normal">
          Статус
        </TableCell>
        <TableCell align="right" padding="normal" />
      </TableRow>
    </TableHead>
  );
};

export default DocumentsTableHead;
