import { ChangeEvent, FC, Fragment, useEffect, useMemo, useState } from "react";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import NotFound from "shared/UI/not-found";
import TemplateTree from "./TemplateTree";
import type { TTemplateLabel, ITemplate } from "../types";
import useTemplates from "shared/lib/hooks/useTemplates";
import TemplatesFilter from "features/templates-filter";

const sx = {
  searchContainer: {
    width: {
      xs: "100%",
      sm: "20rem",
    },
  },
  centralBox: {
    minHeight: {
      xs: "calc(100vh - 9.9375rem)",
      sm: "calc(100vh - 10.4375rem)",
      md: "calc(100vh - 6.4375rem)",
    },
  },
};

const getTemplateServices = (templates: ITemplate[]) => {
  const result: TTemplateLabel[] = [];
  templates.forEach((template) => {
    if (!result.find((item) => item.title === template.fieldTitle)) {
      result.push({ name: template.fieldName, title: template.fieldTitle });
    }
  });
  return result;
};
const TemplatesWidget: FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { data, isLoading } = useTemplates();
  const [templatesArray, setTemplatesArray] = useState<ITemplate[]>([]);

  const templateGroup: TTemplateLabel[] = useMemo(
    () => templatesArray && getTemplateServices(templatesArray),
    [templatesArray],
  );

  const changeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (data) {
      if (searchValue) {
        setTemplatesArray(
          data.filter((item) =>
            item.sampleTitle.toLowerCase().includes(searchValue.toLowerCase()),
          ),
        );
      } else {
        setTemplatesArray(data);
      }
    }
  }, [searchValue, data]);

  if (isLoading) return <FullSizeCircularProgress />;

  return (
    <Fragment>
      <TemplatesFilter value={searchValue} changeValue={changeSearchValue} />
      {templatesArray.length ? (
        <TemplateTree group={templateGroup} templates={templatesArray} />
      ) : (
        <NotFound sx={sx.centralBox} />
      )}
    </Fragment>
  );
};

export default TemplatesWidget;
