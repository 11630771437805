import { type FC, ReactElement, useMemo } from "react";
import TemplateModal from "shared/template-modal/TemplateModal";
import useTemplateModal from "shared/lib/hooks/useTemplateModal";
import { TemplateModalContext } from ".";

interface TemplateModalContextProviderProps {
  children: ReactElement | ReactElement[];
}

const TemplateModalContextProvider: FC<TemplateModalContextProviderProps> = ({
  children,
}) => {
  const context = useTemplateModal();
  const props = useMemo(() => context, [context]);
  return (
    <TemplateModalContext.Provider value={props}>
      <TemplateModal />
      {children}
    </TemplateModalContext.Provider>
  );
};

export default TemplateModalContextProvider;
