import type { IUser } from "app/types/user";

const getFormDefaultValues = (user: IUser | null) => ({
  defaultValues: {
    patient: {
      lastName: "",
      secondName: "",
      firstName: "",
      birthDate: "",
      personalNumber: "",
      gender: "1",
      placeWork: "",
      positionWork: "",
      address: {
        country: {
          name: "Республика Беларусь",
          code: "112",
        },
        region: {
          name: "",
          code: "",
        },
        city: {
          name: "",
          code: "",
        },
        street: "",
        house: "",
        flat: "",
      },
    },
    document: {},
    organization: {
      department: "",
    },
    diagnosis: {
      code: "",
      name: "",
    },
    doctor: {
      firstName: user?.firstName || "",
      secondName: user?.secondName || "",
      lastName: user?.lastName || "",
    },
    headDoctor: {
      firstName: "",
      secondName: "",
      lastName: "",
    },
  },
});

export default getFormDefaultValues;
