import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import { forwardRef, useMemo } from "react";
import useDictionary from "shared/lib/hooks/useDictionary";
import FormatDate from "shared/lib/helpers/format/DateFormat";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import StyledTableCell from "shared/UI/table/table-cell";
import StyledTableRow from "shared/UI/table/table-row";
import { HeadCells } from "../config";
import useContract from "shared/lib/hooks/useContract";
import useJournals from "shared/lib/hooks/useJournals";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import { searchbarSelector } from "pages/journals/[service]/redux/SearchbarSlice";

const sx = {
  tableContainer: {
    "@media print": {
      p: "1.5rem",
    },
  },
  tableTitle: {
    display: "none",
    "@media print": {
      display: "block",
    },
  },
  table: {
    minWidth: 650,
    overflowWrap: "anywhere",
    hyphens: "manual",
    "th, td": {
      fontSize: ".75rem !important",
      p: ".25rem !important",
    },
    "@media print": {
      tr: {
        bgcolor: "transparent !important",
      },
    },
  },
};

const HistologyJournal = forwardRef<HTMLTableElement>((props, ref) => {
  const { range, department } = useAppSelector(searchbarSelector);
  const dictionary = useDictionary();
  const { data: contractData } = useContract();
  const { data: rows } = useJournals();

  const currentDepartment = useMemo(
    () =>
      OptionFormat.returnOptionValue("department", department, dictionary.data),
    [department, dictionary.data],
  );

  if (rows)
    return (
      <Box sx={{ width: "100%", mt: 2 }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer ref={ref} sx={sx.tableContainer}>
            <Box sx={sx.tableTitle}>
              <Typography variant="subtitle1" component="p">
                {contractData?.client?.name}
              </Typography>
              <Typography variant="subtitle1" component="p">
                {currentDepartment}
              </Typography>
              <Typography
                sx={{ textAlign: "right" }}
                variant="subtitle1"
                component="p"
              >
                Начат {FormatDate.toNormalDate(new Date(range.dateFrom))}
              </Typography>
              <Typography
                sx={{ textAlign: "right" }}
                variant="subtitle1"
                component="p"
              >
                Окончен {FormatDate.toNormalDate(new Date(range.dateTill))}
              </Typography>
              <Typography
                sx={{ textAlign: "center" }}
                variant="h5"
                component="h5"
              >
                ЖУРНАЛ
              </Typography>
              <Typography
                sx={{ textAlign: "center" }}
                variant="subtitle1"
                component="p"
              >
                регистрации биопсийного (операционного) материала, направляемого
                на патогистологическое
              </Typography>
              <Typography
                sx={{ textAlign: "center" }}
                variant="subtitle1"
                component="p"
              >
                исследование
              </Typography>
            </Box>
            <Table
              sx={sx.table}
              aria-labelledby="journal-histology"
              size="small"
            >
              <TableHead>
                <StyledTableRow>
                  {HeadCells.map((cell) => (
                    <StyledTableCell
                      align="center"
                      sx={{ verticalAlign: "top" }}
                      key={cell.id}
                    >
                      {cell.label}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={row.guid}>
                    <StyledTableCell width="3%" align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell width="8%">
                      {row.fio}
                      {row.birthday &&
                        `, ${FormatDate.toNormalDate(new Date(row.birthday))}`}
                    </StyledTableCell>
                    <StyledTableCell width="9%">{row.address}</StyledTableCell>
                    <StyledTableCell width="9%">
                      {row.diagnosis}
                    </StyledTableCell>
                    <StyledTableCell width="8%" align="center">
                      {FormatDate.toNormalDate(
                        new Date(row.dateReceiptMaterial),
                      )}
                    </StyledTableCell>
                    <StyledTableCell width="8%" />
                    <StyledTableCell width="8%" align="center">
                      {row.marking}
                    </StyledTableCell>
                    <StyledTableCell width="10%" align="center">
                      {OptionFormat.returnOptionValue(
                        "material",
                        row.localization,
                        dictionary.data,
                      )}
                    </StyledTableCell>
                    <StyledTableCell width="11%">
                      {row.pathologicalProcess}
                    </StyledTableCell>
                    <StyledTableCell width="7%" align="center">
                      {row.count}
                    </StyledTableCell>
                    <StyledTableCell width="8%">{row.doctor}</StyledTableCell>
                    <StyledTableCell width="10%">
                      {row.conclusion}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    );
  return null;
});

export default HistologyJournal;
