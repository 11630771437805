export default class FormatDate {
  static toNormalDate(rawDate: string | Date | number): string {
    const timestamp = Date.parse(String(rawDate));
    if (Number.isNaN(timestamp)) {
      return "—";
    }
    const date = new Date(timestamp);
    let dd = String(date.getDate());
    if (Number(dd) < 10) dd = `0${dd}`;

    let mm = String(date.getMonth() + 1);
    if (Number(mm) < 10) mm = `0${mm}`;

    const yy = String(date.getFullYear());

    return `${dd}.${mm}.${yy}`;
  }

  static getDate(value: string): string {
    let date;
    const dateToday = new Date();
    if (value === "today") {
      date = dateToday;
    } else if (value === "tomorrow") {
      const dateTomorrow = new Date();
      dateTomorrow.setDate(dateToday.getDate() + 1);
      date = dateTomorrow;
    } else if (value === "yesterday") {
      const dateYesterday = new Date();
      dateYesterday.setDate(dateToday.getDate() - 1);
      date = dateYesterday;
    }

    return date
      ? date.toLocaleDateString().split(".").reverse().join("-")
      : value;
  }
}
