import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { ReferenceBookResponse } from "app/types/referenceBook";
import type { TDiagnosis, TDiagnosisFromICD } from "../types";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_URL_DEV}/${process.env.REACT_APP_API_REFERENCE_BOOK}`
    : `${process.env.REACT_APP_URL_PROD}/${process.env.REACT_APP_API_REFERENCE_BOOK}`;

export const diagnosisAPI = createApi({
  reducerPath: "diagnosisAPI",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: (build) => ({
    fetchDiagnosis: build.query<TDiagnosis[], string>({
      query: (data) => ({
        url: "/diagnosis",
        params: { diagnosis: data },
      }),
      transformResponse: (
        rawResult: ReferenceBookResponse<TDiagnosis[]>,
      ): TDiagnosis[] => rawResult.data,
    }),
    fetchCategoryDiagnosis: build.query<TDiagnosis[], number>({
      query: (data) => ({
        url: "/mkb-category-diagnosis",
        params: { parentId: data },
      }),
      transformResponse: (
        rawResult: ReferenceBookResponse<TDiagnosis[]>,
      ): TDiagnosis[] => rawResult.data,
    }),
    fetchDiagnosisFromMKB: build.query<TDiagnosisFromICD[], string>({
      query: (data) => ({
        url: "/mkb-diagnosis",
        params: { diagnosis: data },
      }),
      transformResponse: (
        rawResult: ReferenceBookResponse<TDiagnosisFromICD[]>,
      ): TDiagnosisFromICD[] => rawResult.data,
    }),
  }),
});

export const { useFetchCategoryDiagnosisQuery } = diagnosisAPI;

export const fetchDiagnosis = diagnosisAPI.endpoints.fetchDiagnosis.initiate;
export const fetchDiagnosisFromMKB =
  diagnosisAPI.endpoints.fetchDiagnosisFromMKB.initiate;

export const useFetchDiagnosisFromMKBQueryState =
  diagnosisAPI.endpoints.fetchDiagnosisFromMKB.useQueryState;
