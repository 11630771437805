/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "shared/redux/store";

interface ContractsSearchbarState {
  searchValue: string;
}

const initialState: ContractsSearchbarState = {
  searchValue: "",
};

export const contractsSearchbarSlice = createSlice({
  name: "contractsSearchbar",
  initialState,
  reducers: {
    changeSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
  },
});

export const { changeSearchValue } = contractsSearchbarSlice.actions;

export const searchbarSelector = (state: RootState) => state.contractsSearchbar;

export default contractsSearchbarSlice.reducer;
