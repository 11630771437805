import type { FC } from "react";
import ErrorFormat from "shared/lib/helpers/format/ErrorFormat";
import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const sx = {
  title: {
    fontWeight: "bold",
  },
};

interface Props {
  title: string;
  message: FetchBaseQueryError | SerializedError;
}

const Msg: FC<Props> = (props) => {
  const { title, message } = props;
  return (
    <Box>
      <Typography sx={sx.title} component="h3">
        {title}
      </Typography>
      <Typography component="p">{ErrorFormat.getMessage(message)}</Typography>
    </Box>
  );
};

export default Msg;
