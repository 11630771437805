import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: "1px solid",
    borderColor: theme.palette.common.black,
    padding: ".5rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: ".5rem",
  },
})) as typeof TableCell;

export default StyledTableCell;
