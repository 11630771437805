import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Theme,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import type { FC } from "react";
import { Link } from "react-router-dom";
import useAppSelector from "shared/lib/hooks/useAppSelector";
// import { toggleColorMode } from "../../redux/theme/ThemeSlice";
import userSelector from "shared/redux/user/UserSelector";
import { NavbarLinks } from "../config";
// import MaterialUISwitch from "../UI/switch-theme";

const sx = {
  sidebarHeader: {
    p: {
      xs: "0.5rem",
      md: "1.5rem",
    },
    mt: "1rem",
    textAlign: "center",
  },
  avatar: {
    width: "25%",
    height: "25%",
  },
  typography: {
    fontSize: {
      xs: "1rem",
      lg: "1.2rem",
    },
  },
  subtitle: {
    lineHeight: "1rem",
    fontSize: {
      xs: ".75rem",
      md: ".875rem",
    },
  },
  listItemIcon: {
    color: "inherit",
    minWidth: {
      xs: "2rem",
      lg: "2.5rem",
    },
  },
  //   switchBox: {
  //     position: "absolute",
  //     bottom: "1rem",
  //     left: "50%",
  //     transform: "translate(-50%)",
  //   },
  listItemLink: {
    width: "100%",
    color: (theme: Theme) => theme.palette.grey[200],
    "& > a": { width: "inherit" },
  },
};

interface SidebarProps {
  open: () => void;
}

const Sidebar: FC<SidebarProps> = ({ open }) => {
  // const { mode } = useAppSelector(themeSelector);
  const { user } = useAppSelector(userSelector);
  // const dispatch = useAppDispatch();

  return (
    <AppBar
      position="relative"
      sx={(theme) => ({
        minHeight: "100vh",
        bgcolor: theme.palette.primary.main,
      })}
    >
      <Box sx={sx.sidebarHeader}>
        <AccountCircleIcon sx={sx.avatar} />
        <Typography variant="h4" component="h4" sx={sx.typography}>
          {user?.lastName} {user?.firstName} {user?.secondName || ""}
        </Typography>
        <Typography variant="subtitle2" component="p" sx={sx.subtitle} mt={1}>
          {user?.organizationName}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="subtitle2"
          component="p"
          sx={(theme) => ({ p: "0 1rem", color: theme.palette.grey[400] })}
        >
          Главное меню
        </Typography>
        <List sx={{ width: "100%" }}>
          {NavbarLinks.map((link) => (
            <ListItem key={link.to} disablePadding sx={sx.listItemLink}>
              <Link to={link.to}>
                <ListItemButton>
                  <ListItemIcon sx={sx.listItemIcon}>{link.icon}</ListItemIcon>
                  <ListItemText>{link.title}</ListItemText>
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <ListItem disablePadding sx={sx.listItemLink}>
            <ListItemButton onClick={open}>
              <ListItemIcon sx={sx.listItemIcon}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>Выйти</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      {/* <Box sx={sx.switchBox}> */}
      {/*  <MaterialUISwitch */}
      {/*    onChange={() => dispatch(toggleColorMode())} */}
      {/*    checked={Boolean(mode === "dark")} */}
      {/*  /> */}
      {/* </Box> */}
    </AppBar>
  );
};

export default Sidebar;
