import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { TOrganizationData } from "pages/admin/organization/types";
import type {
  ReferenceBookResponse,
  TAddress,
  GetCitiesData,
} from "app/types/referenceBook";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_URL_DEV}/${process.env.REACT_APP_API_REFERENCE_BOOK}`
    : `${process.env.REACT_APP_URL_PROD}/${process.env.REACT_APP_API_REFERENCE_BOOK}`;

const referenceBookAPI = createApi({
  reducerPath: "referenceBookAPI",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: ["Templates"],
  endpoints: (build) => ({
    // address
    fetchCountries: build.query<TAddress[], string>({
      query: (data) => ({
        url: "/address/countries",
        params: { country: data },
      }),
      transformResponse: (
        rawResult: ReferenceBookResponse<TAddress[]>,
      ): TAddress[] => rawResult.data,
    }),
    fetchCities: build.query<TAddress[], GetCitiesData>({
      query: (data) => ({
        url: "/address/cities",
        params: { region: data.region, city: data.city },
      }),
      transformResponse: (
        rawResult: ReferenceBookResponse<TAddress[]>,
      ): TAddress[] => rawResult.data,
    }),
    // organization
    fetchOrganization: build.query<TOrganizationData[], string>({
      query: (data) => ({
        url: "/organizations",
        params: { organization: data },
      }),
      transformResponse: (
        rawResult: ReferenceBookResponse<TOrganizationData[]>,
      ): TOrganizationData[] => rawResult.data,
    }),
  }),
});

export const fetchCountries =
  referenceBookAPI.endpoints.fetchCountries.initiate;
export const fetchCities = referenceBookAPI.endpoints.fetchCities.initiate;
export const fetchOrganization =
  referenceBookAPI.endpoints.fetchOrganization.initiate;

export default referenceBookAPI;
