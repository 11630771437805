import type { ContainerProps } from "@mui/material";
import Container from "@mui/material/Container";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import AppRouter from "pages";
import type { FC } from "react";
import userSelector from "shared/redux/user/UserSelector";

export const sidebarWidth = {
  xs: 0,
  md: "15rem",
  lg: "20rem",
};

const navbarHeight = {
  xs: "3.5rem",
  sm: "4rem",
  md: 0,
};

const minHeight = {
  xs: `calc(100vh - (${navbarHeight.xs} + 2rem))`,
  sm: `calc(100vh - (${navbarHeight.sm} + 2rem))`,
  md: "calc(100vh - 2rem)",
};

const mainContainerSX = (isLogged: boolean) => ({
  ml: isLogged ? sidebarWidth : "auto",
  mt: isLogged ? navbarHeight : "auto",
  minHeight: isLogged ? minHeight : "calc(100vh - 2rem)",
  "@media print": {
    margin: 0,
  },
  width: "auto",
  overflow: "auto",
  paddingBottom: "1rem",
  paddingTop: "1rem",
});

const MainContainer: FC<ContainerProps> = (props) => {
  const { user } = useAppSelector(userSelector);
  return (
    <Container component="main" sx={mainContainerSX(Boolean(user))} {...props}>
      <AppRouter />
    </Container>
  );
};

export default MainContainer;
