import Backdrop from "@mui/material/Backdrop";
import type { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import { useCreateFeedbackMutation, type IFeedbackForm } from "pages/feedback";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import userSelector from "shared/redux/user/UserSelector";
import { FeedbackSuccessfulMessage } from "./FeedbackSuccessfulMessage";
import Msg from "shared/UI/error-message";
import Card from "shared/UI/card";

const sx = {
  backdrop: {
    color: "#fff",
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  filesInput: {
    height: "auto",
    "::file-selector-button": {
      color: "#fff",
      fontSize: "0.9375rem",
      textTransform: "uppercase",
      borderRadius: "4px",
      border: "0",
      bgcolor: "primary.main",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      padding: "8px 22px",
      cursor: "pointer",
    },
    "::file-selector-button:hover": {
      bgcolor: "rgb(35, 58, 101)",
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "::file-selector-button:active": {
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    },
  },
};

const FeedbackForm: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useAppSelector(userSelector);
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<IFeedbackForm>({
    defaultValues: {
      message: "",
      files: null,
    },
  });
  const [createFeedback, { isSuccess, error, isLoading }] =
    useCreateFeedbackMutation();

  const changeFilesHandler = (e: ChangeEvent<HTMLInputElement>) => {
    clearErrors("files");
    const { files } = e.target;
    if (files) {
      let size = 0;
      Array.from(files).forEach((file) => {
        size += file.size;
      });
      if (size > 104857600) {
        setError("files", {
          type: "max",
          message: "Слишком большой размер файла(ов)!",
        });
      } else {
        setValue("files", files);
      }
    }
  };

  const onSubmitHandler = (data: IFeedbackForm) => {
    const formData = new FormData();
    formData.append("message", data.message);
    if (user) {
      formData.append(
        "name",
        `${user.lastName} ${user.firstName} ${user.secondName}`,
      );
      formData.append("guid", user.guid);
      formData.append("organization", user.organization);
      formData.append("organizationName", user.organizationName);
    }
    if (data.files) {
      Array.from(data.files).forEach((file, index) => {
        formData.append(`media_${index + 1}`, file);
      });
    }

    createFeedback(formData);
  };

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (isSuccess) {
      reset({
        message: "",
        files: null,
      });
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error)
      toast.error(<Msg title="Ошибка отправки данных!" message={error} />);
  }, [error]);

  return (
    <Card maxWidth="md" title="Обратная связь">
      <Backdrop sx={sx.backdrop} open={isLoading}>
        <FullSizeCircularProgress />
      </Backdrop>
      <Backdrop sx={sx.backdrop} open={isOpen} onClick={handleClose}>
        <FeedbackSuccessfulMessage close={handleClose} />
      </Backdrop>
      <Typography
        component="p"
        variant="subtitle1"
        sx={{ color: (theme: Theme) => theme.palette.grey[600] }}
      >
        Нашли ошибку? Сообщите нам об этом!
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          name="message"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              error={Boolean(errors.message)}
              helperText={
                Boolean(errors.message) && "Пожалуйста опишите проблему"
              }
              inputProps={{
                sx: { height: "200px !important" },
                placeholder:
                  "Пожалуйста опишите проблему как можно подробнее...",
              }}
              sx={{ mb: 2 }}
              multiline
              type="text"
            />
          )}
        />
        <Typography
          component="p"
          variant="subtitle1"
          sx={{ color: (theme: Theme) => theme.palette.grey[600] }}
        >
          Также можете прикрепить фото или видео! (до 100МБ)
        </Typography>
        <TextField
          onChange={changeFilesHandler}
          fullWidth
          inputProps={{
            multiple: true,
            accept: "image/*,video/*",
            sx: sx.filesInput,
          }}
          type="file"
          sx={{ mb: 2 }}
          error={Boolean(errors.files)}
          helperText={errors.files?.message}
        />
        <Box sx={{ width: "100%", textAlign: "right" }}>
          <Button type="submit" size="large" variant="contained">
            Отправить
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default FeedbackForm;
