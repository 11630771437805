import { FC } from "react";
import Box from "@mui/material/Box";

const Logo: FC = () => {
  return (
    <Box
      component="img"
      alt="belinnovation"
      src="/images/BelinnovationLogo.png"
    />
  );
};

export default Logo;
