import Backdrop from "@mui/material/Backdrop";
import { Button, TextField } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { type FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEditUserMutation } from "shared/api/authApi";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import userSelector from "shared/redux/user/UserSelector";
import type { IEditProfileForm } from "app/types/user";
import Msg from "shared/UI/error-message";

const sx = {
  backdrop: {
    color: "#fff",
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  buttonContainer: {
    textAlign: "right",
  },
  button: {
    width: {
      xs: "100%",
      sm: "auto",
    },
  },
};

const ProfileForm: FC = () => {
  const { user } = useAppSelector(userSelector);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IEditProfileForm>({
    defaultValues: user
      ? {
          firstName: user.firstName,
          secondName: user.secondName,
          lastName: user.lastName,
        }
      : {
          firstName: "",
          secondName: "",
          lastName: "",
        },
  });

  const [edit, { error, isSuccess, isLoading }] = useEditUserMutation();

  const onSubmitHandler: SubmitHandler<IEditProfileForm> = (data) => {
    if (user) edit(data);
  };

  useEffect(() => {
    if (error)
      toast.error(
        <Msg title="Ошибка редактирования профиля!" message={error} />,
      );
  }, [error]);

  useEffect(() => {
    if (isSuccess) toast.success("Профиль успешно отредактирован!");
  }, [isSuccess]);

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <Backdrop sx={sx.backdrop} open={isLoading}>
        <FullSizeCircularProgress />
      </Backdrop>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Введите фамилию" }}
            render={({ field }) => (
              <TextField
                type="text"
                label="Фамилия"
                {...field}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName && errors.lastName.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "Введите имя" }}
            render={({ field }) => (
              <TextField
                type="text"
                label="Имя"
                {...field}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName && errors.firstName.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="secondName"
            control={control}
            render={({ field }) => (
              <TextField type="text" label="Отчество" {...field} fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <Link to="/edit/password">
            <Typography color="primary" ml={1}>
              <u>Сменить пароль</u>
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={4} sm={4} md={6} sx={sx.buttonContainer}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            sx={sx.button}
          >
            Редактировать
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileForm;
