import { lazy, ComponentType } from "react";
import DocumentInputFormPage from "pages/[service]/input-form";
import Print from "pages/[service]/print-form";
import Service from "pages/[service]/home";
import ViewPage from "pages/[service]/view";
import Contacts from "pages/contacts";
import Profile from "pages/profile";
import Feedback from "pages/feedback";
import HomePage from "pages/home";
import JournalsHome from "pages/journals/home";
import Journals from "pages/journals/[service]";
import TemplatesHome from "pages/templates/home";
import Templates from "pages/templates/[service]";

const AdminHome = lazy(() => import("pages/admin/Home"));
const AdminUsers = lazy(() => import("pages/admin/users/home"));
const AdminUserInputFormPage = lazy(
  () => import("pages/admin/users/input-form"),
);
const AdminOrganizationInputFormPage = lazy(
  () => import("pages/admin/organization/input-form"),
);
const AdminContracts = lazy(() => import("pages/admin/contracts/home"));
const AdminContractInputFormPage = lazy(
  () => import("pages/admin/contracts/input-form"),
);

export interface IRoute {
  path: string;
  element: ComponentType;
}

export enum RouteNames {
  HOME = "/",
  EDIT_PROFILE = "/edit/profile",
  EDIT_PASSWORD = "/edit/password",
  SERVICE = "/:contractId/:serviceCode",
  CREATE_DOCUMENT = "/:contractId/:serviceCode/create",
  EDIT_DOCUMENT = "/:contractId/:serviceCode/edit/:documentId",
  VIEW = "/:contractId/:serviceCode/view/:status/:documentId",
  PRINT = "/:contractId/:serviceCode/print/:status/:documentId",
  TEMPLATES = "/templates",
  TEMPLATES_BY_SERVICE_CODE = "/templates/:serviceCode",
  JOURNALS = "/:contractId/journals",
  JOURNAL = "/:contractId/journals/:serviceCode",
  CONTACTS = "/contacts",
  FEEDBACK = "/feedback",

  ADMIN = "/admin",
  ADMIN_USERS = "/admin/users",
  ADMIN_CREATE_USER = "/admin/users/create",
  ADMIN_EDIT_USER = "/admin/users/edit/:userId",
  ADMIN_ORGANIZATION = "/admin/organization",
  ADMIN_CONTRACTS = "/admin/contracts",
  ADMIN_CREATE_CONTRACT = "/admin/contracts/create",
  ADMIN_EDIT_CONTRACT = "/admin/contracts/edit/:contractId",
}

export const mainRoutes: IRoute[] = [
  {
    path: RouteNames.HOME,
    element: HomePage,
  },
  {
    path: RouteNames.CONTACTS,
    element: Contacts,
  },
  {
    path: RouteNames.FEEDBACK,
    element: Feedback,
  },
  {
    path: RouteNames.EDIT_PROFILE,
    element: Profile,
  },
  {
    path: RouteNames.EDIT_PASSWORD,
    element: Profile,
  },
  {
    path: RouteNames.SERVICE,
    element: Service,
  },
  {
    path: RouteNames.CREATE_DOCUMENT,
    element: DocumentInputFormPage,
  },
  {
    path: RouteNames.EDIT_DOCUMENT,
    element: DocumentInputFormPage,
  },
  {
    path: RouteNames.PRINT,
    element: Print,
  },
  {
    path: RouteNames.VIEW,
    element: ViewPage,
  },
  {
    path: RouteNames.TEMPLATES,
    element: TemplatesHome,
  },
  {
    path: RouteNames.TEMPLATES_BY_SERVICE_CODE,
    element: Templates,
  },
  {
    path: RouteNames.JOURNALS,
    element: JournalsHome,
  },
  {
    path: RouteNames.JOURNAL,
    element: Journals,
  },
];

export const adminRoutes: IRoute[] = [
  {
    path: RouteNames.ADMIN,
    element: AdminHome,
  },
  {
    path: RouteNames.ADMIN_USERS,
    element: AdminUsers,
  },
  {
    path: RouteNames.ADMIN_CREATE_USER,
    element: AdminUserInputFormPage,
  },
  {
    path: RouteNames.ADMIN_EDIT_USER,
    element: AdminUserInputFormPage,
  },
  {
    path: RouteNames.ADMIN_ORGANIZATION,
    element: AdminOrganizationInputFormPage,
  },
  {
    path: RouteNames.ADMIN_CONTRACTS,
    element: AdminContracts,
  },
  {
    path: RouteNames.ADMIN_CREATE_CONTRACT,
    element: AdminContractInputFormPage,
  },
  {
    path: RouteNames.ADMIN_EDIT_CONTRACT,
    element: AdminContractInputFormPage,
  },
];
