import { FC, ReactElement, useMemo } from "react";
import DeleteModal from "shared/delete-modal";
import useDeleteModal from "shared/lib/hooks/useDeleteModal";
import { DeleteModalContext } from ".";

interface DeleteModalContextProviderProps {
  children: ReactElement | ReactElement[];
}

const DeleteModalContextProvider: FC<DeleteModalContextProviderProps> = ({
  children,
}) => {
  const context = useDeleteModal();
  const props = useMemo(() => context, [context]);
  return (
    <DeleteModalContext.Provider value={props}>
      <DeleteModal />
      {children}
    </DeleteModalContext.Provider>
  );
};

export default DeleteModalContextProvider;
