import { type FC, useEffect } from "react";
import { toast } from "react-toastify";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import ContractList from "./ContractsList";
import { useFetchContractsQuery } from "shared/api/contractApi";
import Msg from "shared/UI/error-message";
import NotFound from "shared/UI/not-found";

const ContractsWidget: FC = () => {
  const { data, error, isLoading } = useFetchContractsQuery(null);

  useEffect(() => {
    if (error)
      toast.error(<Msg title="Ошибка получения контрактов!" message={error} />);
  }, [error]);

  if (isLoading) return <FullSizeCircularProgress />;
  if (data && data.contracts)
    return <ContractList contracts={data.contracts} />;

  return <NotFound />;
};

export default ContractsWidget;
