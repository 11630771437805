import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import ContractWrapper from "widgets/wrappers/ContractWrapper";
import JournalsWidget from "widgets/journals";

const Journals: FC = () => (
  <ContractWrapper>
    <PageBox>
      <JournalsWidget />
    </PageBox>
  </ContractWrapper>
);

export default Journals;
