import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "shared/api/baseQueryWithReauth";
import type { IEditData, MutationResponse } from "app/types/admin";
import type { IContractForm } from "../types";
import type { ContractListResponse, IContract } from "app/types/contract";

const adminContractsAPI = createApi({
  reducerPath: "adminContractsAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Contracts"],
  endpoints: (build) => ({
    fetchContracts: build.query<ContractListResponse, string>({
      query: () => ({ url: "/contract/admin" }),
      providesTags: () => ["Contracts"],
    }),
    fetchContract: build.query<IContract, string>({
      query: (id) => ({
        url: `/contract/${id}`,
      }),
      providesTags: () => ["Contracts"],
      transformResponse: (rawResult: { contract: IContract }): IContract =>
        rawResult.contract,
    }),
    createContract: build.mutation<MutationResponse, IContractForm>({
      query: (data) => ({
        url: "/contract",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Contracts"],
    }),
    editContract: build.mutation<MutationResponse, IEditData<IContractForm>>({
      query: (data) => ({
        url: `/contract/${data.guid}`,
        method: "PUT",
        body: data.payload,
      }),
      invalidatesTags: ["Contracts"],
    }),
  }),
});

export const {
  useFetchContractsQuery,
  useFetchContractQuery,
  useCreateContractMutation,
  useEditContractMutation,
} = adminContractsAPI;

export default adminContractsAPI;
