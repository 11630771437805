import type { FC, ReactElement } from "react";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import useDictionary from "shared/lib/hooks/useDictionary";
import useJournals from "shared/lib/hooks/useJournals";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Msg from "shared/UI/error-message";

interface JournalWrapperProps {
  children: ReactElement | null;
}

const JournalWrapper: FC<JournalWrapperProps> = ({ children }) => {
  const dictionary = useDictionary();
  const journals = useJournals();

  useEffect(() => {
    if (dictionary.error)
      toast.error(
        <Msg title="Ошибка получения словаря!" message={dictionary.error} />,
      );
  }, [dictionary.error]);

  useEffect(() => {
    if (journals.error)
      toast.error(
        <Msg title="Ошибка получения журнала!" message={journals.error} />,
      );
  }, [journals.error]);

  if (dictionary.isLoading || journals.isFetching)
    return <FullSizeCircularProgress />;
  if (!dictionary.isLoading && !dictionary.isError && journals.isSuccess)
    return children;
  return null;
};

export default JournalWrapper;
