import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { ChangeEvent, FC, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import {
  useFetchDiagnosisFromMKBQueryState,
  fetchDiagnosisFromMKB,
  useFetchCategoryDiagnosisQuery,
} from "../api/diagnosisApi";
import useAppDispatch from "shared/lib/hooks/useAppDispatch";
import useDebounce from "shared/lib/hooks/useDebounce";
import type { TDiagnosis, TDiagnosisFromICD } from "../types";
import CentralBox from "shared/UI/central-box";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import SearchInput from "shared/UI/search-input";
import DiagnosisListByCategory from "./DiagnosisListByCategory";
import DiagnosisListByICD from "./DiagnosisListByICD";
import Msg from "shared/UI/error-message";

const sx = {
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "90%",
      sm: 500,
    },
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
  },
  searchContainer: {
    mb: 2,
  },
  container: {
    height: "20rem",
    minHeight: "100%",
  },
};

interface IProps {
  isOpen: boolean;
  close: () => void;
}

type TBreadcrumb = {
  name: string;
  id: number;
  parentId: number | null;
  nodeCount: number;
};

interface IDiagnosisContentProps {
  clickHandler: (item: TDiagnosis) => void;
  searchValue: string;
  diagnosisArray: TDiagnosisFromICD[];
  categoryData: TDiagnosis[];
}

const DiagnosisContent: FC<IDiagnosisContentProps> = (props) => {
  const { searchValue, diagnosisArray, categoryData, clickHandler } = props;
  if (searchValue)
    return (
      <DiagnosisListByICD list={diagnosisArray} clickHandler={clickHandler} />
    );

  return (
    <DiagnosisListByCategory
      categories={categoryData}
      clickHandler={clickHandler}
    />
  );
};

const DiagnosisReferencebook: FC<IProps> = (props) => {
  const [parentId, setParentId] = useState<number>(0);
  const { setValue } = useFormContext();
  const initialState = [
    {
      name: "Справочник",
      id: 0,
      parentId: null,
      nodeCount: 1,
    },
  ];
  const [breadcrumbs, setBreadcrumbs] = useState<TBreadcrumb[]>(initialState);
  const [searchValue, setSearchValue] = useState<string>("");
  const [diagnosisArray, setDiagnosisArray] = useState<TDiagnosisFromICD[]>([]);
  const { isOpen, close } = props;
  const dispatch = useAppDispatch();

  const category = useFetchCategoryDiagnosisQuery(parentId);

  const changeSearchValue = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);
  const diagnosisState = useFetchDiagnosisFromMKBQueryState(searchValue);

  const getDiagnosis = useDebounce(async (diagnosis: string) => {
    dispatch(fetchDiagnosisFromMKB(diagnosis)).then((res) => {
      if (res.data) setDiagnosisArray(res.data);
    });
  }, 500);

  const clickHandler = (item: TDiagnosis) => {
    if (item.nodeCount) {
      setBreadcrumbs((prevState) => [
        ...prevState,
        {
          name: item.code || "",
          id: item.id,
          parentId: item.parentId || null,
          nodeCount: item.nodeCount || 0,
        },
      ]);
      setParentId(item.id);
    } else {
      setValue("diagnosis", {
        code: item.code,
        name: item.name,
      });
      close();
    }
  };

  const breadcrumbClickHandler = (breadcrumb: TBreadcrumb) => {
    setBreadcrumbs((prevState) => [
      ...prevState.slice(0, prevState.indexOf(breadcrumb) + 1),
    ]);
    setParentId(breadcrumb.id);
  };

  useEffect(() => {
    if (searchValue) {
      getDiagnosis(searchValue);
    } else {
      setDiagnosisArray([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (category.error)
      toast.error(
        <Msg title="Ошибка получения диагнозов!" message={category.error} />,
      );
  }, [category.error]);

  useEffect(() => {
    if (diagnosisState.error)
      toast.error(
        <Msg
          title="Ошибка получения диагнозов!"
          message={diagnosisState.error}
        />,
      );
  }, [diagnosisState.error]);

  return (
    <Modal open={isOpen} onClose={close} aria-labelledby="diagnosis-modal">
      <Box sx={sx.modalContent}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
          {breadcrumbs.map((breadcrumb) => (
            <Button
              key={breadcrumb.id}
              onClick={() => breadcrumbClickHandler(breadcrumb)}
              color="primary"
            >
              {breadcrumb.name}
            </Button>
          ))}
        </Breadcrumbs>
        <Box sx={sx.searchContainer}>
          <SearchInput
            placeholder="Введите название диагноза..."
            value={searchValue}
            changeHandler={changeSearchValue}
          />
        </Box>
        <Box sx={sx.container}>
          {diagnosisState.isFetching || category.isFetching ? (
            <CentralBox>
              <FullSizeCircularProgress />
            </CentralBox>
          ) : (
            <DiagnosisContent
              clickHandler={clickHandler}
              categoryData={category.data || []}
              diagnosisArray={diagnosisArray}
              searchValue={searchValue}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default DiagnosisReferencebook;
