import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import type { FC } from "react";
import useAppDispatch from "shared/lib/hooks/useAppDispatch";
import { logout } from "shared/redux/user/UserSlice";

const sx = {
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.default",
    borderRadius: "4px",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  },
  modalButtonBox: {
    display: "flex",
    justifyContent: "space-between",
    mt: "2rem",
    width: "75%",
    ml: "auto",
    mr: "auto",
  },
};

interface ModalProps {
  isOpen: boolean;
  close: () => void;
}

const LogoutModal: FC<ModalProps> = ({ isOpen, close }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  return (
    <Modal open={isOpen} onClose={close} aria-labelledby="logout-modal">
      <Box sx={sx.modalContent}>
        <Typography
          id="modal-quit"
          variant="h5"
          component="h2"
          color="text.primary"
        >
          Вы уверены, что хотите выйти?
        </Typography>
        <Box sx={sx.modalButtonBox}>
          <Button
            size="large"
            variant="outlined"
            color="error"
            onClick={() => {
              dispatch(logout());
              close();
            }}
          >
            Выйти
          </Button>
          <Button
            size="large"
            variant="outlined"
            color={theme.palette.mode === "light" ? "primary" : "info"}
            onClick={close}
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LogoutModal;
