import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  InputAdornment,
  ListItemIcon,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { ChangeEvent, type FC, MouseEvent, useState } from "react";
import { useTemplateModalContext } from "shared/lib/context/TemplateModal";
import useTemplates from "shared/lib/hooks/useTemplates";

interface IProps {
  label: string;
  name: string;
  maxRows?: number;
  value: string;
  changeHandler: (value: string) => void;
}

const sx = {
  paper: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 14,
      right: 0,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateX(50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const TextFieldWithTemplate: FC<IProps> = (props) => {
  const { label, name, maxRows, value, changeHandler } = props;
  const context = useTemplateModalContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const templates = useTemplates();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePick = (text: string) => {
    changeHandler(value ? `${value} ${text}` : text);
    handleClose();
  };
  const addTemplate = () => {
    if (context && context.openCreateTemplateMode)
      context.openCreateTemplateMode(name, label);
    handleClose();
  };

  return (
    <Box>
      <TextField
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          changeHandler(e.target.value)
        }
        label={label}
        maxRows={maxRows}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        fullWidth
        multiline
        InputLabelProps={{ shrink: Boolean(value) || isFocused }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Шаблоны">
                <IconButton
                  onClick={handleClick}
                  aria-controls={open ? "template-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  edge="end"
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Menu
        anchorEl={anchorEl}
        id="template-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: sx.paper,
        }}
        transformOrigin={{ horizontal: "right", vertical: 14 }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        {Boolean(templates.data?.length) && (
          <Box sx={{ maxHeight: "12rem", overflow: "auto", pb: 1 }}>
            {templates.data?.map((template) => {
              if (template.fieldName === name) {
                return (
                  <MenuItem
                    key={template.id}
                    onClick={() => handlePick(template.sampleText)}
                  >
                    {template.sampleTitle}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Box>
        )}
        {Boolean(templates.data?.length) && <Divider sx={{ mb: 1 }} />}
        <MenuItem onClick={addTemplate}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>{" "}
          Добавить
        </MenuItem>
      </Menu>
    </Box>
  );
};

TextFieldWithTemplate.defaultProps = {
  maxRows: 20,
};

export default TextFieldWithTemplate;
