import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import type { FC } from "react";
import type { Theme } from "@mui/material";
import type { ITemplate, TTemplateLabel } from "../types";
import TemplateLabel from "features/template-label";
import TemplateItem from "features/template-item";
import Paper from "@mui/material/Paper";

const sx = {
  treeView: {
    flexGrow: 1,
    overflowY: "auto",
  },
  treeItem: {
    borderBottom: ".0625rem solid",
    borderColor: (theme: Theme) => theme.palette.grey[400],
  },
};

interface TemplateTreeProps {
  group: TTemplateLabel[];
  templates: ITemplate[];
}

const TemplateTree: FC<TemplateTreeProps> = ({ group, templates }) => {
  return (
    <Paper elevation={1} sx={{ mt: 2, p: 2 }}>
      <TreeView
        aria-label="template navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={sx.treeView}
      >
        {group?.map((groupItem) => (
          <TreeItem
            key={groupItem.name}
            nodeId={groupItem.name}
            label={<TemplateLabel service={groupItem} />}
          >
            {templates?.map((template) => {
              if (groupItem.name === template.fieldName) {
                return (
                  <TreeItem
                    key={template.id}
                    nodeId={String(template.id)}
                    sx={sx.treeItem}
                    label={<TemplateItem item={template} />}
                    title={template.sampleText}
                  />
                );
              }
              return null;
            })}
          </TreeItem>
        ))}
      </TreeView>
    </Paper>
  );
};

export default TemplateTree;
