import Backdrop from "@mui/material/Backdrop";
import { Button, TextField } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { type FC, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEditUserMutation } from "shared/api/authApi";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import userSelector from "shared/redux/user/UserSelector";
import type { IEditPasswordForm } from "app/types/user";
import Msg from "shared/UI/error-message";

const sx = {
  backdrop: {
    color: "#fff",
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  buttonContainer: {
    textAlign: "right",
  },
  button: {
    width: {
      xs: "100%",
      sm: "auto",
    },
  },
};

const PasswordForm: FC = () => {
  const { user } = useAppSelector(userSelector);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IEditPasswordForm>({
    defaultValues: {
      password: "",
      newPassword: "",
      repeatPassword: "",
    },
  });

  const [edit, { error, isSuccess, isLoading }] = useEditUserMutation();

  const onSubmitHandler: SubmitHandler<IEditPasswordForm> = (data) => {
    if (user) {
      const payload = {
        ...data,
        firstName: user.firstName,
        lastName: user.lastName,
        secondName: user.secondName,
      };
      edit(payload);
    }
  };

  useEffect(() => {
    if (error)
      toast.error(
        <Msg title="Ошибка редактирования пароля!" message={error} />,
      );
  }, [error]);

  useEffect(() => {
    if (isSuccess) toast.success(`Пароль успешно изменен!`);
  }, [isSuccess]);

  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <Backdrop sx={sx.backdrop} open={isLoading}>
        <FullSizeCircularProgress />
      </Backdrop>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="newPassword"
            control={control}
            rules={{ required: `Введите новый пароль` }}
            render={({ field }) => (
              <TextField
                type="text"
                label="Новый пароль"
                {...field}
                error={Boolean(errors.newPassword)}
                helperText={errors.newPassword && errors.newPassword.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="repeatPassword"
            control={control}
            rules={{
              validate: (value) => value === getValues("newPassword"),
            }}
            render={({ field }) => (
              <TextField
                type="text"
                label="Повторите пароль"
                {...field}
                error={Boolean(errors.repeatPassword)}
                helperText={
                  errors.repeatPassword && errors.repeatPassword.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="password"
            control={control}
            rules={{ required: "Введите старый пароль" }}
            render={({ field }) => (
              <TextField
                type="text"
                label="Старый пароль"
                {...field}
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={4} sm={4} md={6}>
          <Link to="/edit/profile">
            <Typography color="primary" ml={1}>
              <u>Редактировать профиль</u>
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={4} sm={4} md={6} sx={sx.buttonContainer}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            sx={sx.button}
          >
            Сменить пароль
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PasswordForm;
