import Typography from "@mui/material/Typography";
import { FC } from "react";

const Copyright: FC = () => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 8, mb: 4 }}
    >
      Copyright © Belinnovation {new Date().getFullYear()}.
    </Typography>
  );
};

export default Copyright;
