import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { ChangeEvent, FC, MouseEvent, useState } from "react";
import type { Data, Order } from "app/types/document";
import FormatDate from "shared/lib/helpers/format/DateFormat";
import {
  getComparator,
  stableSort,
} from "shared/lib/helpers/table-sorting/Sorting";
import DocumentMenu from "features/documents-tooltip-menu";
import DocumentsTableHead from "./DocumentsTableHead";
import getStatus from "../helpers/getStatus";

const sx = {
  menu: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 14,
      right: 0,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateX(50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

interface TableProps {
  rows: Data[];
}

const DocumentsTable: FC<TableProps> = ({ rows }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("dateCreate");
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.guid);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [currentRow, setCurrentRow] = useState<Data | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };
  const handleOpenMenu = (event: MouseEvent<HTMLElement>, row: Data) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="documents">
            <DocumentsTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort<Data>(rows, getComparator(order, orderBy)).map(
                (row) => {
                  const isItemSelected = isSelected(row.guid);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.guid)}
                      key={row.guid}
                      selected={isItemSelected}
                    >
                      <TableCell align="center">
                        {rows.indexOf(row) + 1}
                      </TableCell>
                      <TableCell>{row.fio}</TableCell>
                      <TableCell align="left">
                        {FormatDate.toNormalDate(new Date(row.birthDate || ""))}
                      </TableCell>
                      <TableCell align="left">
                        {FormatDate.toNormalDate(
                          new Date(row.dateCreate.split(" ")[0]),
                        )}{" "}
                        {row.dateCreate.split(" ")[1]}
                      </TableCell>
                      <TableCell align="left">
                        {getStatus(row.status)}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Меню">
                          <IconButton
                            onClick={(event) => handleOpenMenu(event, row)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
          <DocumentMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: sx.menu,
            }}
            transformOrigin={{ horizontal: "right", vertical: 14 }}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            row={currentRow}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default DocumentsTable;
