import type { IDictionary, TValue } from "app/types/dictionary";

export default class OptionFormat {
  static getOption(
    name: string,
    dictionary: IDictionary[] | undefined,
  ): TValue[] {
    const currentDictionary = dictionary?.find((item) => item.name === name);
    if (currentDictionary) {
      return currentDictionary.values;
    }
    return [{ value: "", name: "" }];
  }

  static returnOptionValue(
    name: string,
    value: string | undefined,
    dictionary: IDictionary[] | undefined,
  ): string {
    let result = "";
    if (value) {
      const temp = dictionary?.filter(
        (element: IDictionary) => element.name === name,
      );
      if (temp && temp[0] && temp[0].values) {
        const tempValue = temp[0].values.filter(
          (element: TValue) => Number(element.value) === Number(value),
        );
        if (tempValue[0]) result = tempValue[0].name;
      }
    }
    return result;
  }
}
