import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArticleIcon from "@mui/icons-material/Article";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import InfoIcon from "@mui/icons-material/Info";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

export const NavbarLinks = [
  {
    to: "/",
    title: "Контракты",
    icon: <ArticleIcon />,
  },
  {
    to: "/edit/profile",
    title: "Профиль",
    icon: <AccountBoxIcon />,
  },
  {
    to: "/templates",
    title: "Шаблоны",
    icon: <TextSnippetIcon />,
  },
  {
    to: "/admin",
    title: "Администрирование",
    icon: <AdminPanelSettingsIcon />,
  },
  {
    to: "/contacts",
    title: "Контакты",
    icon: <InfoIcon />,
  },
  {
    to: "/feedback",
    title: "Обратная связь",
    icon: <ContactSupportIcon />,
  },
];
