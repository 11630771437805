import { Suspense, type FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { mainRoutes, adminRoutes } from "app/router";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import LoginPage from "pages/login";
import userSelector from "shared/redux/user/UserSelector";
import CentralBox from "shared/UI/central-box";
import FullSizeCircularProgress from "shared//UI/circle-loader";
import useRoles from "shared/lib/hooks/useRoles";

const sx = {
  centralBox: {
    minHeight: {
      xs: "calc(100vh - 19.5625rem)",
      sm: "calc(100vh - 16.5625rem)",
      md: "calc(100vh - 6rem)",
    },
  },
};

const AppRouter: FC = () => {
  const { user } = useAppSelector(userSelector);
  const { isAdmin } = useRoles();

  return (
    <Suspense
      fallback={
        <CentralBox sx={sx.centralBox}>
          <FullSizeCircularProgress />
        </CentralBox>
      }
    >
      <Routes>
        {user ? (
          mainRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.element />}
            />
          ))
        ) : (
          <Route path="/" element={<LoginPage />} />
        )}
        {user &&
          isAdmin &&
          adminRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.element />}
            />
          ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
