import type { FC, ReactElement } from "react";
import FullSizeCircularProgress from "shared/UI/circle-loader";
import useContract from "shared/lib/hooks/useContract";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Msg from "shared/UI/error-message";

interface ContractWrapperProps {
  children: ReactElement;
}

const ContractWrapper: FC<ContractWrapperProps> = ({ children }) => {
  const contract = useContract();

  useEffect(() => {
    if (contract.error)
      toast.error(
        <Msg title="Ошибка получения контракта!" message={contract.error} />,
      );
  }, [contract.error]);

  if (contract.isLoading) return <FullSizeCircularProgress />;
  if (contract.isSuccess) return children;
  return null;
};

export default ContractWrapper;
