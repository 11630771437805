import type { Theme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC, SyntheticEvent, useLayoutEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TType, types } from "shared/lib/constants/animalTypes";

const sx = {
  typography: {
    fontWeight: "bold",
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
};

const PetComponent: FC = () => {
  const { control, setValue, watch, getValues } = useFormContext();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const initialState = Boolean(
    getValues("patient.pet.type") &&
      getValues("patient.pet.name") &&
      getValues("patient.pet.age"),
  );
  const [pet, setPet] = useState<boolean>(initialState);

  useLayoutEffect(() => {
    if (!pet) {
      setValue("patient.pet", {
        name: "",
        type: "",
        age: "",
      });
    }
    setIsLoaded(true);
    // eslint-disable-next-line
  }, [pet]);

  const changeHandler = (e: SyntheticEvent, value: TType | string | null) => {
    if (typeof value !== "string") {
      setValue("patient.pet.type", value?.title ? value.title : "");
    }
  };

  if (isLoaded)
    return (
      <Box>
        <FormControlLabel
          sx={{ ml: 0 }}
          control={
            <Switch
              checked={pet}
              onChange={() => setPet((prevState) => !prevState)}
            />
          }
          label={
            <Typography component="p" variant="subtitle1" sx={sx.typography}>
              Животное
            </Typography>
          }
          labelPlacement="start"
        />
        <Collapse in={pet}>
          <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={4}>
              <Controller
                name="patient.pet.name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    inputProps={{ sx: { textTransform: "capitalize" } }}
                    label="Кличка"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Autocomplete
                freeSolo
                disablePortal
                options={types}
                onChange={changeHandler}
                inputValue={watch("patient.pet.type")}
                onInputChange={changeHandler}
                getOptionLabel={(option) =>
                  typeof option !== "string" ? option.title : ""
                }
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id}>
                    {option.title}
                  </Box>
                )}
                size="small"
                renderInput={(params) => (
                  <Controller
                    name="patient.pet.type"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...params}
                        {...field}
                        type="text"
                        label="Вид"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Controller
                name="patient.pet.age"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="Возраст"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    );
  return null;
};

export default PetComponent;
