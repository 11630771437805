import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "shared/api/baseQueryWithReauth";
import type { IJournalResponse, TJournalData, IJournal } from "../types";

export const journalAPI = createApi({
  reducerPath: "journalAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    fetchJournal: build.query<IJournal[], TJournalData>({
      query: (data) => ({
        url: `/${data.serviceCode}/journal/${data.organizationGuid}`,
        params: data.payload,
      }),
      transformResponse: (rawResult: IJournalResponse): IJournal[] =>
        rawResult.journal,
    }),
  }),
});

export const { useFetchJournalQuery } = journalAPI;
