import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import CentralBox from "../central-box";

export const MaterialUICircularProgress = styled(CircularProgress)(
  ({ theme }) => ({
    width: "6rem",
    color:
      theme.palette.mode === "light" ? "primary.main" : theme.palette.grey[200],
  }),
) as typeof CircularProgress;

const FullSizeCircularProgress: FC = () => {
  return (
    <CentralBox>
      <MaterialUICircularProgress size="lg" />
    </CentralBox>
  );
};

export default FullSizeCircularProgress;
