import { type FC, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "shared/UI/card";
import ProfileForm from "features/profile-form";
import PasswordForm from "features/profile-password-form";
import getPath from "shared/lib/helpers/get-content/PathURL";

const Profile: FC = () => {
  const [profile, setProfile] = useState<boolean>(true);
  const location = useLocation();

  useLayoutEffect(() => {
    const path = getPath(location.pathname);
    setProfile(path === "profile");
  }, [location]);

  return (
    <Card maxWidth="sm" title={profile ? "Профиль" : "Пароль"}>
      {profile ? <ProfileForm /> : <PasswordForm />}
    </Card>
  );
};

export default Profile;
