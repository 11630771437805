import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import ContractsWidget from "widgets/contracts";

const Home: FC = () => (
  <PageBox>
    <ContractsWidget />
  </PageBox>
);

export default Home;
