import { useParams } from "react-router-dom";
import { useFetchContractQuery } from "shared/api/contractApi";
import { useFetchDictionaryQuery } from "shared/api/dictionaryApi";
import type { TServiceCode } from "app/types/app";
import type { TOrganization } from "app/types/contract";

const servicesWithDictionary = ["histology"];

const useDictionary = () => {
  const params = useParams();
  const id: string = params.contractId || "";
  const serviceCode = params.serviceCode as TServiceCode;
  const { data: contractData } = useFetchContractQuery(id);
  const organizationGuid: TOrganization["guid"] =
    contractData?.service?.guid || "";

  const dictionaryData = {
    serviceCode,
    organizationGuid,
  };
  const dictionaryOptions = {
    skip: !organizationGuid || !servicesWithDictionary.includes(serviceCode),
  };

  const dictionary = useFetchDictionaryQuery(dictionaryData, dictionaryOptions);

  return dictionary;
};

export default useDictionary;
