import { ThemeProvider, createTheme } from "@mui/material/styles";
import { type FC, useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import Navbar from "widgets/navbar";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import themeSelector from "shared/redux/theme/ThemeSelector";
import userSelector from "shared/redux/user/UserSelector";
import { themeSettings } from "./theme";
import AuthWrapper from "widgets/wrappers/AuthWrapper";
import AppBox from "shared/UI/app-box";
import MainContainer from "widgets/main-container";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.scss";

const App: FC = () => {
  const { mode } = useAppSelector(themeSelector);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const { user } = useAppSelector(userSelector);

  return (
    <ThemeProvider theme={theme}>
      <AppBox>
        <ToastContainer position="top-right" transition={Slide} theme={mode} />
        <AuthWrapper>
          <Router>
            {user && <Navbar />}
            <MainContainer maxWidth={false} />
          </Router>
        </AuthWrapper>
      </AppBox>
    </ThemeProvider>
  );
};

export default App;
