import { useParams } from "react-router-dom";
import { useFetchContractQuery } from "shared/api/contractApi";
import type { TServiceCode } from "app/types/app";
import type { TOrganization } from "app/types/contract";
import { useFetchJournalQuery } from "../../../pages/journals/[service]/api/journalApi";
import useAppSelector from "./useAppSelector";
import { searchbarSelector } from "../../../pages/journals/[service]/redux/SearchbarSlice";

const useJournals = () => {
  const { range, department } = useAppSelector(searchbarSelector);
  const params = useParams();
  const id: string = params.contractId || "";
  const serviceCode = params.serviceCode as TServiceCode;
  const { data: contractData } = useFetchContractQuery(id);
  const organizationGuid: TOrganization["guid"] =
    contractData?.service?.guid || "";

  const payload = Number(department)
    ? { dateFrom: range.dateFrom, dateTill: range.dateTill, department }
    : { dateFrom: range.dateFrom, dateTill: range.dateTill };

  const journalData = {
    serviceCode,
    organizationGuid,
    payload,
  };
  const journalOptions = { skip: !organizationGuid || !serviceCode };
  const journals = useFetchJournalQuery(journalData, journalOptions);

  return journals;
};

export default useJournals;
