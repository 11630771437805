export type TType = {
  id: number;
  title: string;
};

export const types = [
  {
    id: 1,
    title: `Собака`,
  },
  {
    id: 2,
    title: `Кошка`,
  },
  {
    id: 3,
    title: `Другое`,
  },
];

export const sortTypes = (string: string): TType[] => {
  let result: TType[] = [];
  if (!string) {
    result = types;
  } else {
    types.forEach((type) => {
      if (
        type.title.includes(string) ||
        type.title.toLowerCase().includes(string)
      ) {
        result.push(type);
      }
    });
  }
  return result;
};
