import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { type FC, useEffect } from "react";
import { toast } from "react-toastify";
import type { Theme } from "@mui/material";
import { useDeleteTemplateMutation } from "pages/templates/[service]/api/templateApi";
import { useDeleteModalContext } from "shared/lib/context/DeleteModal";
import { useTemplateModalContext } from "shared/lib/context/TemplateModal";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import userSelector from "shared/redux/user/UserSelector";
import type { ITemplate } from "widgets/templates/types";
import Msg from "shared/UI/error-message";

const sx = {
  label: {
    fontSize: {
      xs: "1.25rem",
      md: "1.5rem",
    },
    cursor: "pointer",
    color: "text.primary",
  },
  templateBox: {
    p: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  templateWrapper: {
    maxHeight: "12rem",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "6",
    WebkitBoxOrient: "vertical",
  },
  templateText: {
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
    ml: ".5rem",
  },
};

interface ITemplateItem {
  item: ITemplate;
}

const TemplateItem: FC<ITemplateItem> = ({ item }) => {
  const { user } = useAppSelector(userSelector);
  const [deleteTemplate, deleteOptions] = useDeleteTemplateMutation();

  const context = useDeleteModalContext();
  const templateContext = useTemplateModalContext();

  const deleteHandler = (id: string) => {
    if (context)
      context.open(() => deleteTemplate({ id, guid: user?.guid || "" }));
  };

  const editHandler = () => {
    if (templateContext) templateContext.openEditTemplateMode(item);
  };

  useEffect(() => {
    if (deleteOptions.isSuccess) toast.success(deleteOptions.data.message);
  }, [deleteOptions.data?.message, deleteOptions.isSuccess]);

  useEffect(() => {
    if (deleteOptions.error)
      toast.error(
        <Msg title="Ошибка удаления шаблона!" message={deleteOptions.error} />,
      );
  }, [deleteOptions.error]);

  return (
    <Box sx={sx.templateBox}>
      <Box sx={sx.templateWrapper}>
        <FormLabel
          sx={{ ...sx.label, fontSize: { xs: "1rem", md: "1.25rem" } }}
        >
          {item.sampleTitle}
        </FormLabel>
        <Typography component="p" variant="subtitle1" sx={sx.templateText}>
          {item.sampleText}
        </Typography>
      </Box>
      <Box>
        <IconButton color="primary" onClick={editHandler}>
          <EditIcon />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => deleteHandler(item.id.toString())}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TemplateItem;
