import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Fragment, type FC } from "react";
import useDocument from "shared/lib/hooks/useDocument";
import FormatDate from "shared/lib/helpers/format/DateFormat";
import NumberFormat from "shared/lib/helpers/format/NumberFormat";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import useDictionary from "shared/lib/hooks/useDictionary";
import useContract from "shared/lib/hooks/useContract";
import type { TMaterial } from "../../../../entities/materials-table/components/MaterialsTable";

const sx = {
  a4: {
    maxWidth: "21cm",
    margin: "auto",
  },
  typographyTitle: {
    textAlign: "center",
    fontWeight: "bold",
  },
  table: {
    minWidth: 650,
    border: "1px solid",
    "th, td": {
      border: "1px solid",
      p: "0 0.5rem",
    },
    marginBottom: ".625rem",
    fontSize: ".8rem",
  },
  signContainer: {
    display: "flex",
    alignItems: "end",
    gap: 1,
    justifyContent: "end",
    ml: "auto",
  },
};

const HistologyResult: FC = () => {
  const { data: contractData } = useContract();
  const { data: dictionaryData } = useDictionary();
  const { data: documentData } = useDocument();

  const isFirstTableShown = Boolean(
    documentData?.referral.igh.unspecial[0]?.block.number,
  );

  const isSecondTableShown = Boolean(
    documentData?.referral.igh.special[0]?.block.number,
  );

  return (
    <Box style={sx.a4}>
      <Box sx={{ pageBreakAfter: "always" }}>
        <Typography variant="subtitle1" component="p" sx={{ mb: 2 }}>
          {contractData?.service?.name}
        </Typography>
        <Typography variant="h5" component="h3" sx={sx.typographyTitle}>
          Заключение
        </Typography>
        <Typography variant="h6" component="h4" sx={sx.typographyTitle}>
          патогистологического исследования № {documentData?.conclusion?.number}
        </Typography>
        <Typography variant="subtitle1" component="p">
          В {contractData?.client?.name}
        </Typography>
        <Typography variant="subtitle1" component="p">
          {OptionFormat.returnOptionValue(
            "department",
            documentData?.organization?.department,
            dictionaryData,
          )}
        </Typography>
        <Typography variant="subtitle1" component="p">
          1. Фамилия, собственное имя, отчество (если таковое имеется) пациента:{" "}
          {documentData?.patient.lastName} {documentData?.patient.firstName}{" "}
          {documentData?.patient.secondName}
        </Typography>
        <Typography variant="subtitle1" component="p">
          2. Идентификационный номер пациента:{" "}
          {documentData?.patient.personalNumber}
        </Typography>
        <Typography variant="subtitle1" component="p">
          3. Число, месяц, год рождения:{" "}
          {FormatDate.toNormalDate(documentData?.patient.birthDate || "")}
        </Typography>
        <Typography variant="subtitle1" component="p">
          4. Пол: {documentData?.patient.gender === "1" ? "муж." : "жен."}
        </Typography>
        <Typography variant="subtitle1" component="p">
          5. Адрес места жительства (места пребывания):{" "}
          {documentData?.patient.address.address}
        </Typography>
        <Typography variant="subtitle1" component="p">
          6. Дата забора биопсийного (операционного) материала:{" "}
          {FormatDate.toNormalDate(documentData?.referral.samplingDate)}
        </Typography>
        <Typography variant="subtitle1" component="p">
          7. Способ получения биопсийного (операционного) материала:{" "}
          {OptionFormat.returnOptionValue(
            "method",
            documentData?.referral.method,
            dictionaryData,
          )}
        </Typography>
        <Typography variant="subtitle1" component="p">
          8. Дата доставки:{" "}
          {FormatDate.toNormalDate(documentData?.referral.sendDate)}
        </Typography>
        <Typography variant="subtitle1" component="p">
          9. Время доставки (заполняется при экспресс биопсиях с указанием
          времени в часах и минутах):{" "}
          {documentData?.referral.sendTime &&
            documentData?.referral.sendTime.slice(0, 5)}
        </Typography>
        <Typography variant="subtitle1" component="p">
          10. Дата начала гистологической обработки:{" "}
          {documentData?.referral.processingDate &&
            FormatDate.toNormalDate(documentData?.referral.processingDate)}
        </Typography>
        <Typography variant="subtitle1" component="p">
          11. Время начала гистологической обработки:{" "}
          {documentData?.referral.processingDate?.split(" ")[1]}
        </Typography>
        <Typography variant="subtitle1" component="p">
          12. Количество фрагментов тканей, органов или последов, взятых на
          патогистологическое исследование:{" "}
          {documentData?.referral.materials.reduce(
            (acc: number, curr: TMaterial) => acc + Number(curr.count),
            0,
          )}
        </Typography>
        <Typography variant="subtitle1" component="p">
          13. Наименование назначенных окрасок и (или) реакций:
        </Typography>
        {isFirstTableShown && (
          <TableContainer>
            <Table sx={sx.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Блок</TableCell>
                  <TableCell>Наименование антитела</TableCell>
                  <TableCell>Результат</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentData?.referral.igh.unspecial?.map(({ block }: any) => (
                  <Fragment key={block.number}>
                    {block.value.map((item: any, index: number) => (
                      <Fragment key={item.result}>
                        {index === 0 ? (
                          <TableRow>
                            <TableCell
                              align="center"
                              rowSpan={block.value.length}
                            >
                              {block.number}
                            </TableCell>
                            <TableCell align="center">
                              {item.antibody}
                            </TableCell>
                            <TableCell align="center">
                              {item.percent}%{" "}
                              {OptionFormat.returnOptionValue(
                                "unspecialResult",
                                item.result,
                                dictionaryData,
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell align="center">
                              {item.antibody}
                            </TableCell>
                            <TableCell align="center">
                              {item.percent}%{" "}
                              {OptionFormat.returnOptionValue(
                                "unspecialResult",
                                item.result,
                                dictionaryData,
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isSecondTableShown && (
          <TableContainer>
            <Table sx={sx.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Блок</TableCell>
                  <TableCell>Наименования антител</TableCell>
                  <TableCell>% позитивных клеток</TableCell>
                  <TableCell>
                    Интенсивность позитивно окрашенных клеток
                  </TableCell>
                  <TableCell>Allred score</TableCell>
                  <TableCell>Интерпретация</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documentData?.referral.igh.special?.map(({ block }: any) => (
                  <Fragment key={block.number}>
                    <TableRow>
                      <TableCell rowSpan={5}>{block.number}</TableCell>
                      <TableCell>ER</TableCell>
                      <TableCell align="center">{block.value?.er}%</TableCell>
                      <TableCell>
                        {OptionFormat.returnOptionValue(
                          "specialErBal",
                          block.value?.erResult,
                          dictionaryData,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        Allred score {block.value?.erAll}
                      </TableCell>
                      <TableCell>позитивная реакция</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>PR</TableCell>
                      <TableCell align="center">{block.value?.pr}%</TableCell>
                      <TableCell>
                        {OptionFormat.returnOptionValue(
                          "specialPrBal",
                          block.value?.prResult,
                          dictionaryData,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        Allred score {block.value?.prAll}
                      </TableCell>
                      <TableCell>позитивная реакция</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Her2/neu</TableCell>
                      <TableCell align="center">
                        Score {block.value?.spHer2}
                      </TableCell>
                      <TableCell align="center" colSpan={2} />
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell>Ki67</TableCell>
                      <TableCell align="center">{block.value?.ki}%</TableCell>
                      <TableCell align="center" colSpan={3} />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5}>
                        Описание: {block.value?.description}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box>
          <Typography variant="subtitle1" component="p">
            14. Макроскопическое описание:
          </Typography>
          {documentData?.referral.materials.length > 0 &&
            documentData?.referral.materials.map(
              (material: any, index: number) => (
                <Typography
                  variant="subtitle1"
                  component="p"
                  ml={1}
                  key={documentData?.referral.materials.indexOf(material)}
                >
                  {index + 1}. {material.number} (
                  {OptionFormat.returnOptionValue(
                    "material",
                    material.materialType,
                    dictionaryData,
                  )}
                  ) {material.macroDescription}
                </Typography>
              ),
            )}
        </Box>
        <Box>
          <Typography variant="subtitle1" component="p">
            15. Микроскопическое описание:
          </Typography>
          {documentData?.referral.materials.length > 0 &&
            documentData?.referral.materials.map(
              (material: any, index: number) => (
                <Typography
                  ml={1}
                  key={documentData?.referral.materials.indexOf(material)}
                  variant="subtitle1"
                  component="p"
                >
                  {index + 1}. {material.number} (
                  {OptionFormat.returnOptionValue(
                    "material",
                    material.materialType,
                    dictionaryData,
                  )}
                  ) {material.type}
                  {material.t && `T${material.t}`}
                  {material.n && `N${material.n}`}
                  {material.g &&
                    `G ${NumberFormat.toRomanNumbers(Number(material.g))}`}{" "}
                  {material.morph_txt} {material.microDescription}
                </Typography>
              ),
            )}
        </Box>
        <Typography variant="subtitle1" component="p">
          16. Патогистологическое заключение:{" "}
          {documentData?.referral.zakluchenie}
        </Typography>
        <Typography variant="subtitle1" component="p">
          17. Рекомендации к патогистологическому заключению:{" "}
          {documentData?.referral.recomendation}
        </Typography>
        <Grid container spacing={1} mt={1} sx={{ pageBreakInside: "avoid" }}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" component="p">
              Врач-патологоанатом, проводивший исследование
            </Typography>
          </Grid>
          <Grid item container xs={8} sx={sx.signContainer}>
            <Grid
              item
              xs={3}
              sx={{ textAlign: "center", ml: "auto", mt: "auto" }}
            >
              <Box sx={{ borderTop: "1px solid" }}>
                <Typography variant="subtitle2" component="p">
                  (подпись)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center", ml: 1, mt: "auto" }}>
              <Typography variant="subtitle1" component="p">
                {documentData?.referral.doctor}
              </Typography>
              <Box sx={{ borderTop: "1px solid" }}>
                <Typography variant="subtitle2" component="p">
                  (фамилия, инициалы)
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HistologyResult;
