import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  QueryTemplateData,
  CreateTemplateData,
  MutationTemplateResponse,
  ITemplate,
  MutationTemplateData,
} from "../../../../widgets/templates/types";
import type { ReferenceBookResponse } from "app/types/referenceBook";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_URL_DEV}/${process.env.REACT_APP_API_REFERENCE_BOOK}`
    : `${process.env.REACT_APP_URL_PROD}/${process.env.REACT_APP_API_REFERENCE_BOOK}`;

export const templateAPI = createApi({
  reducerPath: "templateAPI",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: ["Templates"],
  endpoints: (build) => ({
    fetchTemplates: build.query<ITemplate[], QueryTemplateData>({
      query: (data) => ({
        url: "/templates",
        params: {
          guid: data.guid,
          moduleTitle: data.serviceCode,
        },
      }),
      providesTags: () => ["Templates"],
      transformResponse: (
        rawResult: ReferenceBookResponse<ITemplate[]>,
      ): ITemplate[] => rawResult.data,
    }),
    createTemplate: build.mutation<
      MutationTemplateResponse,
      CreateTemplateData
    >({
      query: (data) => ({
        url: "/templates",
        method: "POST",
        params: {
          guid: data.guid,
          moduleTitle: data.moduleTitle,
          fieldTitle: data.fieldTitle,
          fieldName: data.fieldName,
        },
        body: {
          sampleTitle: data.sampleTitle,
          sampleText: data.sampleText,
        },
      }),
      invalidatesTags: ["Templates"],
    }),
    editTemplate: build.mutation<
      MutationTemplateResponse,
      MutationTemplateData
    >({
      query: (data) => ({
        url: `/templates/${data.id}`,
        params: {
          guid: data.guid,
        },
        method: "PUT",
        body: data.payload,
      }),
      invalidatesTags: ["Templates"],
    }),
    deleteTemplate: build.mutation<
      MutationTemplateResponse,
      MutationTemplateData
    >({
      query: (data) => ({
        url: `/templates/${data.id}`,
        params: {
          guid: data.guid,
        },
        method: "DELETE",
      }),
      invalidatesTags: ["Templates"],
    }),
  }),
});

export const {
  useFetchTemplatesQuery,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useEditTemplateMutation,
} = templateAPI;
