import refreshAPI from "shared/api/refreshApi";
import authAPI from "shared/api/authApi";
import contractAPI from "shared/api/contractApi";
import dictionaryAPI from "shared/api/dictionaryApi";
import { documentAPI } from "pages/[service]/home";
import { journalAPI } from "pages/journals/[service]";
import { loginAPI } from "pages/login";
import { feedbackAPI } from "pages/feedback";
import { templateAPI } from "pages/templates/[service]";
import { diagnosisAPI } from "widgets/diagnosis";
import referenceBookAPI from "shared/api/referenceBookApi";
import adminOrganizationAPI from "pages/admin/organization/api/adminOrganizationApi";
import adminUsersAPI from "pages/admin/users/api/adminUsersApi";
import adminContractsAPI from "pages/admin/contracts/api/adminContractsApi";

const middleware = [
  refreshAPI.middleware,
  authAPI.middleware,
  loginAPI.middleware,
  dictionaryAPI.middleware,
  feedbackAPI.middleware,
  templateAPI.middleware,
  diagnosisAPI.middleware,
  contractAPI.middleware,
  documentAPI.middleware,
  referenceBookAPI.middleware,
  adminContractsAPI.middleware,
  adminOrganizationAPI.middleware,
  adminUsersAPI.middleware,
  journalAPI.middleware,
];

export default middleware;
