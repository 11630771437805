import Typography from "@mui/material/Typography";
import { FC } from "react";
import type { BoxProps, Theme } from "@mui/material";
import CentralBox from "../central-box";

const sx = {
  typography: {
    color: (theme: Theme) => theme.palette.grey[400],
    textAlign: "center",
  },
};

const NotFound: FC<BoxProps> = (props) => {
  return (
    <CentralBox {...props}>
      <Typography variant="h4" component="h4" sx={sx.typography}>
        Результаты не найдены
      </Typography>
    </CentralBox>
  );
};

export default NotFound;
