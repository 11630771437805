import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const AppBox = styled(Box)(({ theme }) => ({
  minWidth: 300,
  backgroundColor: theme.palette.background.default,
  overflow: "auto",
  minHeight: "100vh",
})) as typeof Box;

export default AppBox;
