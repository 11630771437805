import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";
import type { ContractListResponse, IContract } from "app/types/contract";

const contractAPI = createApi({
  reducerPath: "contractAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    fetchContracts: build.query<ContractListResponse, null>({
      query: () => ({ url: "/contract" }),
    }),
    fetchContract: build.query<IContract, string>({
      query: (id) => ({ url: `/contract/${id}` }),
      transformResponse: (rawResult: { contract: IContract }): IContract =>
        rawResult.contract,
    }),
  }),
});

export const { useFetchContractsQuery, useFetchContractQuery } = contractAPI;

export default contractAPI;
