import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { type FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import StyledTableCell from "shared/UI/table/table-cell";
import MaterialsTableModal from "./MaterialsTableModal";
import MaterialsTableItem from "./MaterialsTableItem";

const initialState = {
  number: "",
  materialType: "1",
  description: "",
  count: "",
};

export type TMaterial = typeof initialState;

const MaterialsTable: FC = () => {
  const [open, setOpen] = useState(false);
  const { watch, setValue, getValues } = useFormContext();
  const [material, setMaterial] = useState<TMaterial>(initialState);
  const [materialIndex, setMaterialIndex] = useState<number>(0);

  const handleClick = () => setOpen(true);

  const close = () => {
    setOpen(false);
    setMaterial(initialState);
    setMaterialIndex(0);
  };

  const add = () => {
    if (materialIndex) {
      getValues("document.materials")[materialIndex - 1] = material;
    } else {
      setValue("document.materials", [
        ...getValues("document.materials"),
        material,
      ]);
    }
    close();
  };

  const editClick = (index: number, item: TMaterial) => {
    setMaterialIndex(index);
    setMaterial(item);
    setOpen(true);
  };

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="materials table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">№</StyledTableCell>
              <StyledTableCell align="center">
                Маркировка емкости
              </StyledTableCell>
              <StyledTableCell align="center">Вид материала</StyledTableCell>
              <StyledTableCell align="center">
                Характер патогистологического процесса
              </StyledTableCell>
              <StyledTableCell align="center">
                Количество или объем
              </StyledTableCell>
              <StyledTableCell align="center">
                <Tooltip title="Добавить материал">
                  <Button variant="contained" onClick={handleClick}>
                    <AddIcon />
                  </Button>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {watch("document.materials")?.map(
              (element: TMaterial, index: number) => (
                <MaterialsTableItem
                  material={element}
                  number={index + 1}
                  editClick={editClick}
                  key={
                    element.count +
                    element.description +
                    element.number +
                    element.materialType
                  }
                />
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <MaterialsTableModal
        material={material}
        changeMaterial={setMaterial}
        add={add}
        open={open}
        close={close}
      />
    </Box>
  );
};

export default MaterialsTable;
