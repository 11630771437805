import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import type { ChangeEvent, FC } from "react";
import SearchInput from "shared/UI/search-input";

const sx = {
  searchContainer: {
    width: {
      xs: "100%",
      sm: "20rem",
    },
  },
};

interface Props {
  value: string;
  changeValue: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TemplatesFilter: FC<Props> = (props) => {
  const { value, changeValue } = props;

  return (
    <Paper elevation={1} sx={{ p: 2 }}>
      <Box sx={sx.searchContainer}>
        <SearchInput
          placeholder="Введите название шаблона..."
          value={value}
          changeHandler={changeValue}
        />
      </Box>
    </Paper>
  );
};

export default TemplatesFilter;
