/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import type { ThemeState } from "app/types/theme";

const initialState: ThemeState = {
  mode: "light",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleColorMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
      localStorage.setItem("theme", state.mode);
    },
    setInitialColorMode: (state) => {
      const theme = localStorage.getItem("theme");
      if (theme && (theme === "light" || theme === "dark")) {
        state.mode = theme;
      }
    },
  },
});

export const { toggleColorMode, setInitialColorMode } = themeSlice.actions;

export default themeSlice.reducer;
