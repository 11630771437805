import FolderIcon from "@mui/icons-material/Folder";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FC, useState } from "react";
import type { TDiagnosis, TDiagnosisFromICD } from "../types";
import NotFound from "shared/UI/not-found";

const sx = {
  list: {
    height: "100%",
    overflow: "auto",
  },
};

interface IListItemProps {
  item: TDiagnosisFromICD;
  clickHandler: (item: TDiagnosis) => void;
}

const ListItem: FC<IListItemProps> = ({ item, clickHandler }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={`${item.code} ${item.name}`} />
      </ListItemButton>
      <Collapse in={isOpen}>
        <List>
          {item.values.map((element) => (
            <ListItemButton
              key={element.id}
              sx={{ pl: 4 }}
              onClick={() => clickHandler(element)}
            >
              <ListItemIcon>
                <TextSnippetIcon />
              </ListItemIcon>
              <ListItemText
                sx={{ overflow: "hidden" }}
                primary={`${element.code} ${element.name}`}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

interface IDiagnosisListByICDProps {
  list: TDiagnosisFromICD[];
  clickHandler: (item: TDiagnosis) => void;
}

const DiagnosisListByICD: FC<IDiagnosisListByICDProps> = ({
  list,
  clickHandler,
}) => {
  if (list.length)
    return (
      <List sx={sx.list}>
        {list.map((item) => (
          <ListItem key={item.id} item={item} clickHandler={clickHandler} />
        ))}
      </List>
    );

  return <NotFound />;
};

export default DiagnosisListByICD;
