import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import type { FC } from "react";
import OptionFormat from "shared/lib/helpers/format/OptionFormat";
import StyledTableCell from "shared/UI/table/table-cell";
import StyledTableRow from "shared/UI/table/table-row";
import useDictionary from "shared/lib/hooks/useDictionary";
import type { TMaterial } from "../../../../entities/materials-table/components/MaterialsTable";

interface IProps {
  material: TMaterial;
  number: number;
}

const MaterialItem: FC<IProps> = ({ material, number }) => {
  const dictionary = useDictionary();

  return (
    <StyledTableRow>
      <StyledTableCell align="center">{number}</StyledTableCell>
      <StyledTableCell align="center">{material.number}</StyledTableCell>
      <StyledTableCell align="center">
        {OptionFormat.returnOptionValue(
          "material",
          material.materialType,
          dictionary.data,
        )}
      </StyledTableCell>
      <StyledTableCell align="center">{material.description}</StyledTableCell>
      <StyledTableCell align="center">{material.count}</StyledTableCell>
    </StyledTableRow>
  );
};

interface ITableProps {
  materials: TMaterial[];
}

const sx = {
  table: {
    minWidth: 500,
    "@media print": {
      tr: {
        bgcolor: "transparent !important",
      },
    },
  },
};

const MaterialsTablePrint: FC<ITableProps> = ({ materials }) => {
  return (
    <Box>
      <TableContainer sx={{ mb: 1 }}>
        <Table sx={sx.table} aria-label="materials table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">№</StyledTableCell>
              <StyledTableCell align="center">
                Маркировка емкости
              </StyledTableCell>
              <StyledTableCell align="center">Вид материала</StyledTableCell>
              <StyledTableCell align="center">
                Характер патогистологического процесса
              </StyledTableCell>
              <StyledTableCell align="center">
                Количество или объем
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materials.map((material: TMaterial, index: number) => (
              <MaterialItem
                material={material}
                number={index + 1}
                key={
                  material.count +
                  material.description +
                  material.number +
                  material.materialType
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MaterialsTablePrint;
