import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "shared/api/baseQueryWithReauth";
import type { IEditData, MutationResponse } from "app/types/admin";
import type { UsersQueryResponse, IUserForm } from "../types";
import type { IUserResponse } from "app/types/user";

const adminUsersAPI = createApi({
  reducerPath: "adminUsersAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Users"],
  endpoints: (build) => ({
    fetchUsers: build.query<UsersQueryResponse, string>({
      query: (data) => ({
        url: `/user/list/${data}`,
      }),
      providesTags: () => ["Users"],
    }),
    fetchUser: build.query<IUserResponse, string>({
      query: (id) => ({
        url: `/user/${id}`,
      }),
      providesTags: () => ["Users"],
    }),
    createUser: build.mutation<MutationResponse, IUserForm>({
      query: (data) => ({
        url: "/user",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    editUser: build.mutation<MutationResponse, IEditData<IUserForm>>({
      query: (data) => ({
        url: `/user/${data.guid}`,
        method: "PUT",
        body: data.payload,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useFetchUsersQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useFetchUserQuery,
} = adminUsersAPI;

export default adminUsersAPI;
