import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export default class ErrorFormat {
  static getMessage = (error: FetchBaseQueryError | SerializedError) => {
    if ("message" in error) {
      return error.message;
    }
    if ("error" in error) {
      return error.error;
    }
    if ("data" in error) {
      const message: any = error.data;
      if ("error" in message) return message.error.description;
      if ("message" in message) return message.message;
    }
    return null;
  };
}
