import { useParams } from "react-router-dom";
import { useFetchContractQuery } from "shared/api/contractApi";

const useContract = () => {
  const params = useParams();
  const contractId: string = params.contractId || "";
  const contract = useFetchContractQuery(contractId);
  return contract;
};

export default useContract;
