import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import type { FC } from "react";
import { useTemplateModalContext } from "shared/lib/context/TemplateModal";

const sx = {
  modalContent: {
    position: "absolute",
    top: "50%",
    left: {
      xs: "5%",
      sm: "50%",
    },
    right: {
      xs: "5%",
      sm: "auto",
    },
    transform: {
      xs: "translate(0, -50%)",
      sm: "translate(-50%, -50%)",
    },
    width: "auto",
    maxWidth: 500,
    bgcolor: "background.default",
    borderRadius: "4px",
    boxShadow: 24,
    p: {
      xs: 2,
      sm: 4,
    },
  },
};

const TemplateModal: FC = () => {
  const context = useTemplateModalContext();

  return (
    <Modal
      open={Boolean(context?.isOpen)}
      onClose={context?.close}
      aria-labelledby="template-modal"
    >
      <Box sx={sx.modalContent}>
        <Typography
          id="modal-quit"
          variant="h5"
          component="h2"
          color="text.primary"
          sx={{ textAlign: "center" }}
        >
          {context?.template.text ? "Редактировать шаблон" : "Добавить шаблон"}
        </Typography>
        <Box component="div" sx={{ mt: 2 }}>
          <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12}>
              <TextField
                value={context?.template.title}
                onChange={context?.changeHandler}
                name="title"
                label="Название шаблона"
                type="text"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <TextField
                value={context?.template.text}
                onChange={context?.changeHandler}
                name="text"
                label="Текст шаблона"
                type="text"
                multiline
                maxRows={10}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} sx={{ ml: "auto" }}>
              <Button
                size="large"
                variant="contained"
                onClick={context?.submit}
                fullWidth
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default TemplateModal;
