import type { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC, KeyboardEvent, useLayoutEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputFormat from "shared/lib/helpers/format/InputFormat";
import { gender } from "shared/lib/constants/selectOptions";

const sx = {
  typography: {
    fontWeight: "bold",
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
  capitalize: {
    textTransform: "capitalize",
  },
};

const ChildComponent: FC = () => {
  const { control, setValue, watch, getValues } = useFormContext();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const initialState = Boolean(getValues("patient.child.lastName"));
  const [child, setChild] = useState<boolean>(initialState);

  useLayoutEffect(() => {
    if (!child) {
      setValue("patient.child", {
        lastName: "",
        firstName: "",
        secondName: "",
        birthDate: "",
        gender: "1",
        personalNumber: "",
      });
    }
    setIsLoaded(true);
    // eslint-disable-next-line
  }, [child]);

  const changeHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    const result = InputFormat.changePassportNumber(e);
    setValue("patient.child.personalNumber", result);
  };

  if (isLoaded)
    return (
      <Box>
        <FormControlLabel
          sx={{ ml: 0 }}
          control={
            <Switch
              checked={child}
              onChange={() => setChild((prevState) => !prevState)}
            />
          }
          label={
            <Typography component="p" variant="subtitle1" sx={sx.typography}>
              Новорожденный
            </Typography>
          }
          labelPlacement="start"
        />
        <Collapse in={child}>
          <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={4}>
              <Controller
                name="patient.child.lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    inputProps={{ sx: sx.capitalize }}
                    label="Фамилия"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Controller
                name="patient.child.firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="Имя"
                    inputProps={{ sx: sx.capitalize }}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Controller
                name="patient.child.secondName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="Отчество"
                    inputProps={{ sx: sx.capitalize }}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Controller
                name="patient.child.birthDate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    label="Дата рождения"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <TextField
                type="text"
                value={watch("patient.child.personalNumber")}
                onKeyUp={changeHandler}
                label="Идентификационный номер"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl fullWidth>
                <InputLabel size="small" id="gender-label">
                  Пол
                </InputLabel>
                <Controller
                  name="patient.child.gender"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="gender-label"
                      label="Пол"
                      size="small"
                    >
                      {gender.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    );
  return null;
};

export default ChildComponent;
