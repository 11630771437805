import type { KeyboardEvent } from "react";

export default class InputFormat {
  static changePassportNumber(event: KeyboardEvent<HTMLInputElement>): string {
    let { value } = event.target as HTMLInputElement;
    const { length } = value;
    const { keyCode, key } = event;

    if (keyCode === 8) {
      value = value.substring(0, length - 1);
      return value;
    }
    if (length < 7 || (length > 7 && length < 11) || length === 13) {
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105)
      ) {
        value += key;
      }
    }
    if (length === 7 || (length >= 11 && length < 13)) {
      if (keyCode >= 65 && keyCode <= 90) {
        value += String.fromCharCode(keyCode);
      }
    }

    return value;
  }

  static changeDiagnosisCode(event: KeyboardEvent<HTMLInputElement>): string {
    let { value } = event.target as HTMLInputElement;
    const { length } = value;
    const { keyCode, key } = event;

    if (keyCode === 8) {
      if (length === 5) {
        value = value.substring(0, length - 3);
      } else if (length === 4) {
        value = value.substring(0, length - 2);
      } else {
        value = value.substring(0, length - 1);
      }
      return value;
    }
    if (length < 1) {
      if (keyCode >= 65 && keyCode <= 90) {
        value += String.fromCharCode(keyCode);
      }
    }
    if (length >= 1) {
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105)
      ) {
        value += key;
        if (length === 2) {
          value += ".";
        }
      }
    }

    return value;
  }
}
