import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import ContractWrapper from "widgets/wrappers/ContractWrapper";
import JournalsCard from "entities/journals-card";

const sx = {
  pageBox: {
    display: "flex",
    alignItems: "center",
  },
};

const Home: FC = () => (
  <ContractWrapper>
    <PageBox sx={sx.pageBox}>
      <JournalsCard />
    </PageBox>
  </ContractWrapper>
);

export default Home;
