export type TRegion = {
  name: string;
  code: string;
};

export const regions = [
  {
    name: `Брестская`,
    code: `1`,
  },
  {
    name: `Витебская`,
    code: `2`,
  },
  {
    name: `Гомельская`,
    code: `3`,
  },
  {
    name: `Гродненская`,
    code: `4`,
  },
  {
    name: `Город Минск`,
    code: `5`,
  },
  {
    name: `Минская`,
    code: `6`,
  },
  {
    name: `Могилёвская`,
    code: `7`,
  },
];

export const sortRegions = (string: string) => {
  let result: TRegion[] = [];
  if (!string) {
    result = regions;
  } else {
    regions.forEach((region) => {
      if (
        region.name.includes(string) ||
        region.name.toLowerCase().includes(string)
      ) {
        result.push(region);
      }
    });
  }
  return result;
};
