import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { MouseEvent, FC } from "react";
import { useTemplateModalContext } from "shared/lib/context/TemplateModal";
import type { TTemplateLabel } from "widgets/templates/types";

const sx = {
  label: {
    fontSize: {
      xs: "1.25rem",
      md: "1.5rem",
    },
    cursor: "pointer",
    color: "text.primary",
  },
  templateBox: {
    p: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

interface ITemplateLabelProps {
  service: TTemplateLabel;
}

const TemplateLabel: FC<ITemplateLabelProps> = ({ service }) => {
  const context = useTemplateModalContext();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const clickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (context && context.openCreateTemplateMode)
      context.openCreateTemplateMode(service.name, service.title);
  };

  return (
    <Box sx={sx.templateBox}>
      <FormLabel sx={sx.label}>{service.title}</FormLabel>
      {matches ? (
        <Button
          variant="outlined"
          onClick={clickHandler}
          startIcon={<AddIcon />}
        >
          Добавить
        </Button>
      ) : (
        <IconButton color="primary" onClick={clickHandler}>
          <AddIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default TemplateLabel;
