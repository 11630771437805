import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import type { ChangeEvent, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAppDispatch from "shared/lib/hooks/useAppDispatch";
import useAppSelector from "shared/lib/hooks/useAppSelector";
import {
  changeRange,
  searchbarSelector,
} from "pages/[service]/home/redux/SearchbarSlice";
import type { TRange } from "app/types/document";
import useRoles from "shared/lib/hooks/useRoles";

const DocumentsSearchbar: FC = () => {
  const { isAdmin, isDoc } = useRoles();
  const params = useParams();
  const navigate = useNavigate();
  const navigateToCreatePage = () =>
    navigate(`/${params.contractId}/${params.serviceCode}/create`);

  const dispatch = useAppDispatch();
  const { range } = useAppSelector(searchbarSelector);
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(
      changeRange({
        name: e.target.name as keyof TRange,
        value: e.target.value,
      }),
    );

  return (
    <Paper elevation={2}>
      <Box sx={{ p: ".75rem" }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} md={3}>
            <TextField
              type="date"
              name="dateFrom"
              value={range.dateFrom}
              onChange={changeHandler}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              type="date"
              name="dateTill"
              value={range.dateTill}
              onChange={changeHandler}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={4} sm={8} md={3} sx={{ marginLeft: "auto" }}>
            {(isAdmin || isDoc) && (
              <Button
                variant="contained"
                onClick={navigateToCreatePage}
                color="primary"
                fullWidth
              >
                <AddIcon /> Создать
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default DocumentsSearchbar;
