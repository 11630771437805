/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "shared/redux/store";
import FormatDate from "shared/lib/helpers/format/DateFormat";
import type { TRange } from "app/types/document";

interface JournalsSearchbarState {
  range: {
    dateFrom: string;
    dateTill: string;
  };

  department: string;
}

type TChangeRange = {
  name: keyof TRange;
  value: string;
};

const initialState: JournalsSearchbarState = {
  range: {
    dateFrom: FormatDate.getDate("today"),
    dateTill: FormatDate.getDate("tomorrow"),
  },
  department: "0",
};

export const journalsSearchbarSlice = createSlice({
  name: "journalsSearchbar",
  initialState,
  reducers: {
    changeRange: (state, action: PayloadAction<TChangeRange>) => {
      state.range[action.payload.name] = action.payload.value;
    },
    changeDepartment: (state, action: PayloadAction<string>) => {
      state.department = action.payload;
    },
  },
});

export const { changeRange, changeDepartment } = journalsSearchbarSlice.actions;

export const searchbarSelector = (state: RootState) => state.journalsSearchbar;

export default journalsSearchbarSlice.reducer;
