import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const ContractListWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "1rem",
}));

export default ContractListWrapper;
