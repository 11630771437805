import FolderIcon from "@mui/icons-material/Folder";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import type { FC } from "react";
import type { TDiagnosis } from "../types";
import NotFound from "shared/UI/not-found";

const sx = {
  list: {
    height: "100%",
    overflow: "auto",
  },
};

interface IDiagnosisListByCategory {
  categories: TDiagnosis[] | undefined;
  clickHandler: (item: TDiagnosis) => void;
}

const DiagnosisListByCategory: FC<IDiagnosisListByCategory> = ({
  categories,
  clickHandler,
}) => {
  if (categories && categories.length) {
    return (
      <List sx={sx.list}>
        {categories?.map((item) => (
          <ListItemButton key={item.id} onClick={() => clickHandler(item)}>
            <ListItemIcon>
              {item.nodeCount ? <FolderIcon /> : <TextSnippetIcon />}
            </ListItemIcon>
            <ListItemText primary={`${item.code} ${item.name}`} />
          </ListItemButton>
        ))}
      </List>
    );
  }
  return <NotFound />;
};

export default DiagnosisListByCategory;
