import { useParams } from "react-router-dom";
import useAppSelector from "./useAppSelector";
import { useFetchTemplatesQuery } from "pages/templates/[service]";
import userSelector from "shared/redux/user/UserSelector";
import type { TServiceCode } from "app/types/app";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ErrorFormat from "shared/lib/helpers/format/ErrorFormat";

const useTemplates = () => {
  const params = useParams();
  const { user } = useAppSelector(userSelector);
  const guid: string = user?.guid || "";
  const serviceCode = params.serviceCode as TServiceCode;
  const templates = useFetchTemplatesQuery({ guid, serviceCode });
  useEffect(() => {
    if (templates.error)
      toast.error(
        `Произошла ошибка получения шаблонов: ${ErrorFormat.getMessage(
          templates.error,
        )}`,
      );
  }, [templates.error]);
  return templates;
};

export default useTemplates;
