import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";

import { ReactNode } from "react";

type TContact = {
  id: number;
  text: string;
  icon: ReactNode;
  linkText: string;
  linkProps: {
    href?: string;
    underline?: "none";
  };
};

export const Contacts: TContact[] = [
  {
    id: 1,
    text: "E-Mail тех.поддержки:",
    icon: <AlternateEmailIcon />,
    linkText: "service@belinnovation.by",
    linkProps: {
      href: "mailto:service@belinnovation.by",
    },
  },
  {
    id: 2,
    text: "Моб.телефон тех.поддержки:",
    icon: <LocalPhoneIcon />,
    linkText: "+375 (33) 303-03-01",
    linkProps: {
      href: "tel:+3753030301",
    },
  },
  {
    id: 3,
    text: "Юридический адрес:",
    icon: <BusinessIcon />,
    linkText: "г.Брест, ул.Карьерная, д.2",
    linkProps: {
      underline: "none",
    },
  },
  {
    id: 4,
    text: "Почтовый адрес:",
    icon: <MailIcon />,
    linkText: "г.Брест, ул.Пионерская, 52, оф.801",
    linkProps: {
      underline: "none",
    },
  },
];
