import type { ForwardedRef } from "react";
import Diagnosis from "widgets/diagnosis";
import HeadDoctor from "entities/head-doctor";
import AutopsyInputForm from "modules/Autopsy/input-form";
import AutopsyPrint from "modules/Autopsy/print-form";
import HistologyInputForm from "modules/Histology/input-form";
import HistologyJournal from "modules/Histology/journal";
import HistologyPrint from "modules/Histology/print-form";
import type { TServiceCode } from "app/types/app";

export default class Content {
  static getJournal(
    service: TServiceCode,
    ref: ForwardedRef<HTMLTableElement>,
  ) {
    switch (service) {
      case "histology":
        return <HistologyJournal ref={ref} />;

      case "autopsy":
        return null;

      default:
        return null;
    }
  }

  static getPrint(service: TServiceCode, status: string) {
    switch (service) {
      case "histology":
        return <HistologyPrint status={status} />;

      case "autopsy":
        return <AutopsyPrint status={status} />;

      default:
        return null;
    }
  }

  static getInputForm(service: TServiceCode) {
    switch (service) {
      case "histology":
        return <HistologyInputForm />;

      case "autopsy":
        return <AutopsyInputForm />;

      default:
        return null;
    }
  }

  static getHeadDoctor(service: TServiceCode) {
    switch (service) {
      case "histology":
        return null;

      case "autopsy":
        return <HeadDoctor />;

      default:
        return null;
    }
  }

  static getDiagnosis(service: TServiceCode) {
    switch (service) {
      case "histology":
        return <Diagnosis />;

      case "autopsy":
        return null;

      default:
        return null;
    }
  }
}
