import type { FC } from "react";
import PageBox from "shared/UI/page-box";
import TemplatesCard from "entities/templates-card";

const sx = {
  pageBox: {
    display: "flex",
    alignItems: "center",
  },
};

const Home: FC = () => (
  <PageBox sx={sx.pageBox}>
    <TemplatesCard />
  </PageBox>
);

export default Home;
