import { useState } from "react";

type TModalState = {
  deleteCallback: () => void;
};

const useDeleteModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<TModalState>({
    deleteCallback: () => null,
  });

  const close = () => {
    setIsOpen(false);
    setModalState({ deleteCallback: () => null });
  };

  const open = (callback: () => void) => {
    setIsOpen(true);
    setModalState({ deleteCallback: callback });
  };

  const deleteItem = () => {
    modalState.deleteCallback();
  };

  return {
    isOpen,
    close,
    open,
    deleteItem,
  };
};

export default useDeleteModal;

export type TDeleteModal = ReturnType<typeof useDeleteModal>;
