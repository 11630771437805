import type { FC } from "react";

import HistologyReferral from "./HistologyReferral";
import HistologyResult from "./HistologyResult";

interface IProps {
  status: string;
}

const HistologyPrint: FC<IProps> = ({ status }) => {
  switch (status) {
    case "referral":
      return <HistologyReferral />;

    case "result":
      return <HistologyResult />;

    default:
      return null;
  }
};

export default HistologyPrint;
