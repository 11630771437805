import SearchIcon from "@mui/icons-material/Search";
import type { Theme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { type FC, useState, KeyboardEvent, SyntheticEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { fetchDiagnosis } from "../api/diagnosisApi";
import useAppDispatch from "shared/lib/hooks/useAppDispatch";
import useDebounce from "shared/lib/hooks/useDebounce";
import type { TDiagnosis } from "../types";
import InputFormat from "shared/lib/helpers/format/InputFormat";
import DiagnosisReferencebook from "./DiagnosisReferencebook";

const sx = {
  typography: {
    fontWeight: "bold",
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
};

const Diagnosis: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { control, setValue, watch } = useFormContext();
  const [diagnosisArray, setDiagnosisArray] = useState<TDiagnosis[]>([]);

  const changeHandler = (
    e: SyntheticEvent,
    value: string | TDiagnosis | null,
  ) => {
    if (typeof value !== "string") {
      setValue("diagnosis", {
        code: value?.CodeICD ? value.CodeICD : "",
        name: value?.name ? value.name : "",
      });
    }
  };
  const getDiagnosis = useDebounce(async (diagnosis: string) => {
    dispatch(fetchDiagnosis(diagnosis)).then((res) => {
      if (res.data) setDiagnosisArray(res.data);
    });
  }, 500);

  const changeDiagnosis = (e: KeyboardEvent<HTMLInputElement>) => {
    const result = InputFormat.changeDiagnosisCode(e);
    setValue("diagnosis.code", result);
    if (result) getDiagnosis(result);
  };

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <Box>
      <DiagnosisReferencebook isOpen={isOpen} close={close} />
      <Typography component="p" variant="subtitle1" sx={sx.typography}>
        Диагноз
      </Typography>
      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={3} sm={4}>
          <Autocomplete
            freeSolo
            options={diagnosisArray}
            onChange={changeHandler}
            size="small"
            getOptionLabel={(option) =>
              typeof option !== "string" ? option.CodeICD : ""
            }
            inputValue={watch("diagnosis").code}
            onKeyUp={changeDiagnosis}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.CodeICD} {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField {...params} type="text" label="МКБ-10" />
            )}
          />
        </Grid>
        <Grid item>
          <IconButton onClick={open}>
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <Controller
            name="diagnosis.name"
            control={control}
            render={({ field }) => (
              <TextField
                type="text"
                size="small"
                {...field}
                fullWidth
                multiline
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Diagnosis;
