import type { FC } from "react";

import AutopsyReferral from "./AutopsyReferral";

interface IProps {
  status: string;
}

const AutopsyPrint: FC<IProps> = ({ status }) => {
  switch (status) {
    case "referral":
      return <AutopsyReferral />;

    case "result":
      return null;

    default:
      return null;
  }
};

export default AutopsyPrint;
