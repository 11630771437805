import { Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import type { FC } from "react";
import { useDeleteModalContext } from "shared/lib/context/DeleteModal";

const sx = {
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.default",
    borderRadius: "4px",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  },
  modalButtonBox: {
    display: "flex",
    justifyContent: "space-between",
    mt: "2rem",
    width: "75%",
    ml: "auto",
    mr: "auto",
  },
};

const DeleteModal: FC = () => {
  const context = useDeleteModalContext();
  const theme = useTheme();
  return (
    <Modal
      open={Boolean(context?.isOpen)}
      onClose={context?.close}
      aria-labelledby="delete-modal"
    >
      <Box sx={sx.modalContent}>
        <Typography
          id="modal-delete"
          variant="h5"
          component="h2"
          color="text.primary"
        >
          Вы уверены, что хотите удалить эту запись?
        </Typography>
        <Box sx={sx.modalButtonBox}>
          <Button
            size="large"
            variant="outlined"
            color="error"
            onClick={() => {
              context?.deleteItem();
              context?.close();
            }}
          >
            Удалить
          </Button>
          <Button
            size="large"
            variant="outlined"
            color={theme.palette.mode === "light" ? "primary" : "info"}
            onClick={context?.close}
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
