import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { MutationFeedbackResponse } from "../types";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_URL_DEV}/${process.env.REACT_APP_API_REFERENCE_BOOK}`
    : `${process.env.REACT_APP_URL_PROD}/${process.env.REACT_APP_API_REFERENCE_BOOK}`;

export const feedbackAPI = createApi({
  reducerPath: "feedbackAPI",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: ["Templates"],
  endpoints: (build) => ({
    createFeedback: build.mutation<MutationFeedbackResponse, FormData>({
      query: (data) => ({
        url: "/feedback",
        body: data,
        method: "POST",
      }),
    }),
  }),
});

export const { useCreateFeedbackMutation } = feedbackAPI;
