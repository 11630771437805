import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "app";
import store from "shared/redux/store";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import "core-js/stable";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </React.StrictMode>,
);

serviceWorkerRegistration.register();
