import { type FC, useRef, useState, Fragment } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import type { Theme } from "@mui/material";
import JournalsTable from "./JournalsTable";
import JournalsSearchbar from "features/journals-searchbar";
import FullSizeCircularProgress from "shared/UI/circle-loader";

const sx = {
  pageBox: {
    display: "flex",
    alignItems: "center",
  },
  backdrop: {
    color: "#fff",
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
};

const JournalsWidget: FC = () => {
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const componentRef = useRef<HTMLTableElement>(null);
  const print = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setIsPrinting(true);
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    },
    removeAfterPrint: true,
  });

  const handlePrint = () => {
    if (componentRef.current) {
      print();
    } else {
      toast.warning("Пожалуйста проверьте правильность данных для печати!");
    }
  };

  return (
    <Fragment>
      <Backdrop sx={sx.backdrop} open={isPrinting}>
        <FullSizeCircularProgress />
      </Backdrop>
      <JournalsSearchbar handlePrint={handlePrint} />
      <JournalsTable ref={componentRef} />
    </Fragment>
  );
};

export default JournalsWidget;
