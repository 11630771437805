import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQueryWithReauth";
import { setUser } from "../redux/user/UserSlice";
import type { IUser, TEditUserData, IUserResponse } from "app/types/user";

const authAPI = createApi({
  reducerPath: "authAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["UserInfo"],
  endpoints: (build) => ({
    fetchInfo: build.query<IUser, null>({
      query: () => ({ url: "/user" }),
      transformResponse: (rawResult: { user: IUser }): IUser => rawResult.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {
          await Promise.reject(error);
        }
      },
      providesTags: () => ["UserInfo"],
    }),
    editUser: build.mutation<IUserResponse, TEditUserData>({
      query: (data) => ({ url: "/user", method: "PUT", body: data }),
      invalidatesTags: ["UserInfo"],
    }),
  }),
});

export const { useFetchInfoQuery, useEditUserMutation } = authAPI;
export const useFetchInfoQueryState = authAPI.endpoints.fetchInfo.useQueryState;
export const fetchInfo = authAPI.endpoints.fetchInfo.initiate;

export default authAPI;
