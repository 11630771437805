import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "shared/api/baseQueryWithReauth";
import type { MutationResponse } from "app/types/admin";
import type { OrganizationQueryResponse, IOrganizationForm } from "../types";

const adminOrganizationAPI = createApi({
  reducerPath: "adminOrganizationAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    fetchOrganization: build.query<OrganizationQueryResponse, string>({
      query: () => ({ url: "/organization" }),
    }),
    editOrganization: build.mutation<MutationResponse, IOrganizationForm>({
      query: (data) => ({
        url: "/organization",
        method: `PUT`,
        body: data,
      }),
    }),
  }),
});

export const { useFetchOrganizationQuery, useEditOrganizationMutation } =
  adminOrganizationAPI;

export default adminOrganizationAPI;
