import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, FC, useLayoutEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputMask from "react-input-mask";
import { useParams } from "react-router-dom";
import type { Theme } from "@mui/material/styles";
import FormatDate from "shared/lib/helpers/format/DateFormat";
import ChildComponent from "entities/child";
import Employee from "entities/employee";

const sx = {
  typography: {
    fontWeight: "bold",
    color: (theme: Theme) =>
      theme.palette.mode === "light" ? "primary.main" : "text.primary",
  },
};

const AutopsyInputForm: FC = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const { control, setValue, watch } = useFormContext();

  const params = useParams();

  useLayoutEffect(() => {
    if (!params.documentId) {
      setValue("document", {
        deathDate: "",
        deathTime: "",
        created: `${FormatDate.getDate(
          "today",
        )} ${new Date().toLocaleTimeString()}`,
        mobilePhone: "",
        stationaryPhone: "",
        sender: {
          positionWork: "",
          firstName: "",
          lastName: "",
          secondName: "",
        },
      });
    }
    setIsReady(true);
  }, [params.documentId, setValue]);

  const changePhoneHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue("document.mobilePhone", e.target.value);
  };

  if (isReady)
    return (
      <Box>
        <ChildComponent />
        <Typography component="p" variant="subtitle1" sx={sx.typography}>
          Вскрытие
        </Typography>
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <Controller
              name="document.deathDate"
              control={control}
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  type="date"
                  label="Дата наступления смерти (мертворождения)"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Controller
              name="document.deathTime"
              control={control}
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  type="time"
                  label="Время наступления смерти (мертворождения)"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Controller
              name="document.created"
              control={control}
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  {...field}
                  type="datetime-local"
                  label="Дата направления на патологоанатомическое вскрытие"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
        <Employee />
        <Typography component="p" variant="subtitle1" sx={sx.typography}>
          Контактный телефон
        </Typography>
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <InputMask
              mask="+375 (99) 999 99 99"
              value={watch("document.mobilePhone")}
              onChange={changePhoneHandler}
            >
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                () => (
                  <TextField
                    type="text"
                    label="Мобильный"
                    fullWidth
                    size="small"
                  />
                )
              }
            </InputMask>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Controller
              name="document.stationaryPhone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="Стационарный"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    );
  return null;
};

export default AutopsyInputForm;
